    .product-tab{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 40px;
        @include _1920{
            margin-bottom: calcFluid(26.67, 40);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
        
    }
    &__headers{
        display: flex;
        margin-bottom: -1px;
        @include _1920{
            margin-bottom: calcFluid(-0.67, -1);
        }
        @include _992{
            flex-direction: column;
            margin-bottom: 10px;
        }

        &__item{
            padding: 16px 60px;
            border: 1px solid #C9C9C9;
            border-radius: 10px 10px 0 0;
            cursor: pointer; 
            display: flex;
            align-items: center;
            justify-content: center;
            @include _1920{
                padding: calcFluid(10.67, 16) calcFluid(40, 60);
                border: calcFluid(0.67, 1) solid #C9C9C9;
                border-radius: calcFluid(6.67, 10) calcFluid(6.67, 10) 0 0;
            }
            @include _992{
                padding: 0;
                border-radius: 40px;
                padding: 12px 0 14px;
            }
            &:not(:last-child){
                margin-right: 18px;
                @include _1920{
                    margin-right: calcFluid(12, 18);
                }
                @include _992{
                    margin-right: 0px;
                    margin-bottom: 6px;
                }
            }
            span{
                font-size: 27px;
                line-height: 33.75px;
                text-align: center;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            &.active{
                cursor: default; 
                border-bottom: 1px solid #F5F5F5;
                background-color: #F5F5F5;
                @include _1920{
                    border-bottom: calcFluid(0.67, 1) solid #F5F5F5;
                }
                @include _992{
                    border-bottom: 1px solid #C9C9C9;
                    opacity: .6;
                }
            }
        }
        &.detail{
            .product-tab__headers__item{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 24px 60px 29px;
                @include _1920{
                    padding: calcFluid(16, 24) calcFluid(40, 60) calcFluid(19.33, 29);
                }
                @include _992{
                    padding: 13px 0 18px;
                }
                &__desc{
                    opacity: .8;
                    font-size: 21px;
                    line-height: 26.25px;
                    margin-top: 5px;
                    @include _1920{
                        font-size: calcFluid(14, 21);
                        line-height: calcFluid(17.50, 26.25);
                        margin-top: calcFluid(3.33, 5);
                    }
                    @include _992{
                        font-size: 14px;
                        line-height: 17.5px;
                        margin-top: 4px;
                    }
                }
            }
        }
    } 
    &__body{
        background-color: #F5F5F5;
        border-top: 1px solid #C9C9C9;
        @include _1920{
            border-top: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border-top: none;
        }
        &.solo{
            background-color: #fff;
            border: none;
        }
    }
    &__wrapper{
        display: none;
        padding: 57px 0;
        flex-direction: column;
        @include _1920{
            padding: calcFluid(38, 57) 0;
        }
        @include _992{
            padding: 46px 0;
        }
        &.solo{
            display: flex;
            padding: 0 0 45px;
            @include _1920{
                padding: 0 0 calcFluid(30, 45);
            }
        }
        &.active{
            display: flex;
        }
    }
    &__info{
        display: flex;
        @include _992{
            flex-direction: column;
        }
    }
    &__image{
        min-width: 705px;
        height: 440px;
        margin: 20px;
        border: 1px solid #272727;
        border-radius: 20px;
        position: relative;
        @include _1920{
            min-width: calcFluid(470, 705);
            height: calcFluid(293.33, 440);
            margin: calcFluid(13.33, 20);
            border: calcFluid(0.67, 1) solid #272727;
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            min-width: calc(100% - 8px);
            height: calcFluidMobile(168, 336);
            margin-left: 8px;
            border-radius: 20px;
        }
        img{
            position: absolute;
            width: 705px;
            height: 440px;
            margin: -20px;
            border-radius: 20px;
            z-index: 2;
            object-fit: cover;
            @include _1920{
                width: calcFluid(470, 705);
                height: calcFluid(293.33, 440);
                margin: calcFluid(-13.33, -20);
                border-radius: calcFluid(13.33, 20);
            }
            @include _992{
                width: 100%;
                height: 100%;
                margin: -8px;
                border-radius: 20px;
            }
        }
        &.solo{
            &::before{
                background-color: #fff;
            }
            &::after{
                background-color: #fff;
            }

        }
        &::before{
            position: absolute;
            content: '';
            background: #f5f5f5;
            display: block;
            top: 88%;
            left: 36%;
            width: 65%;
            height: 59px;
            @include _1920{
                height: calcFluid(39.33, 59);
            }
            @include _992{
                bottom: -1px;
                height: 22px;
            }
        }
        &::after{
            position: absolute;
            content: '';
            background: #f5f5f5;
            display: block;
            width: 20px;
            right: -1px;
            top: -1px;
            height: 30px;
            @include _1920{
                width: calcFluid(13.33, 20);
                right: calcFluid(-0.67, -1);
                top: calcFluid(-0.67, -1);
                height: calcFluid(20, 30);
            }
            @include _992{
                top: -1px;
                right: -1px;
                height: 22px;
                width: 80px;
            }
        }
    }
    &__main{
        padding: 10px 40px;
        width: 100%;
        @include _1920{
            padding: calcFluid(6.67, 10) calcFluid(26.67, 40);
        }
        @include _992{
            padding: 0px;
            margin-top: 30px;
        }
        &__title{
            margin-bottom: 14px;
            @include _1920{
                margin-bottom: calcFluid(9.33, 14);
            }
            @include _992{
                margin-bottom: 10px;
            }
            span{
                font-size: 27px;
                line-height: 33.75px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        &__text{
            width: 90%;
            margin-bottom: 25px;
            @include _1920{
                margin-bottom: calcFluid(16.67, 25);
            }
            @include _992{
                margin-bottom: 18px;
            }
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__old-price{
            margin-bottom: 3px;
            @include _1920{
                margin-bottom: calcFluid(2, 3);
            }
            @include _992{
                margin-bottom: 6px;
            }
            span{
                text-decoration-line: line-through;
                font-size: 27px;
                line-height: 33.75px;
                color: #A9A9A9;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        &__price{
            margin-bottom: 24px;
            @include _1920{
                margin-bottom: calcFluid(16, 24);
            }
            @include _992{
                margin-bottom: 24px;
            }
            span{
                font-size: 48px;
                line-height: 55.2px;
                @include _1920{
                    font-size: calcFluid(32, 48);
                    line-height: calcFluid(36.80, 55.2);
                }
                @include _992{
                    font-size: 30px;
                    line-height: 34.5px;
                }
            } 
        }
        &__btns{
            display: flex;
            width: 87%;
            @include _992{
                width: 100%;
                flex-direction: column;
            }
        }
        &__btn{
            width: 97%;
            margin-right: 25px;
            @include _1920{
                margin-right: calcFluid(16.67, 25);
            }
            @include _992{
                width: 100%;
                margin-right: 0px;
                margin-bottom: 10px;
            }
            &:last-child{
                width: 100%;
            }
        }
    }
}
.product-advantages{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 26px;
    @include _1920{
        margin-top: calcFluid(17.33, 26);
    }
    @include _992{
        flex-direction: column;
        margin-top: 40px;
    }
    &__item{
        display: grid;
        align-items: center;    
        grid-template-columns: 84px 345px;
        margin-right: 140px;
        column-gap: 30px;
        @include _1920{
            grid-template-columns: calcFluid(56, 84) calcFluid(230, 345);
            margin-right: calcFluid(93.33, 140);
            column-gap: calcFluid(20, 30);
        }
        @include _992{
            grid-template-columns: 44px 1fr;
            margin-bottom: 28px;
            column-gap: 28px;
            margin-right: 0px;
        }
        img{
            width: 84px;
            height: 84px;
            object-fit: contain;
            @include _1920{
                width: calcFluid(56, 84);
                height: calcFluid(56, 84);
            }
            @include _992{
                width: 44px;
                height: 44px;
            }
        }
        span{
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            @include _1920{
                font-size: calcFluid(16, 24);
                line-height: calcFluid(20, 30);
            }
            @include _992{
                font-size: 18px;
                line-height: 22.5px;
            }
        }
    }
}
.product-links{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 30px;
    margin-top: 26px;
    @include _1920{
        column-gap: calcFluid(20, 30);
        margin-top: calcFluid(17.33, 26);
    }
    @include _992{
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
    }
    @include _576{
        grid-template-columns: 1fr;
    }
    &__item{
        position: relative;
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        height: 200px;
        padding: 20px 31px;
        cursor: pointer;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            height: calcFluid(133.33, 200);
            padding: calcFluid(13.33, 20) calcFluid(20.67, 31);
        }
        @include _992{
            border-radius: 20px;
            height: 80px;
            width: 100%;
            padding: 0 26px;
            justify-content: space-between;
            align-items: center;
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                grid-column: 1/-1;
                width: 50%;
                margin: 0 auto;
            }
            @include _576{
                width: 100%;
            }
        }
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #E9E9E9C2;
            transition: all .3s ease;
        }
        svg{
            z-index: 2;
            position: absolute;
            top: 18px;
            right: 18px;
            width: 17px;
            height: 17px;
            transition: all .3s ease;
            @include _1920{
                top: calcFluid(12, 18);
                right: calcFluid(12, 18);
                width: calcFluid(11.33, 17);
                height: calcFluid(11.33, 17);
            }
            @include _992{
                width: 17px;
                height: 17px;
                stroke: #000;
                position: initial;
            }
        }
        span{
            display: flex; 
            align-items: center;
            z-index: 2;
            font-size: 27px;
            line-height: 33.75px;
            margin-top: auto;
            height: 57px;
            transition: all .3s ease;
            @include _1920{
                font-size: calcFluid(18, 27);
                line-height: calcFluid(22.50, 33.75);
                height: calcFluid(38, 57);
            }
            @include _992{
                font-size: 21px;
                line-height: 26.25px;
                height: auto;
                margin-top: 0px;
                width: 80%;
            }
        }
        &:hover{
            &::after{
                background-color: #FB474E;
            }
            svg{
                stroke: #fff;
            }
            span{
                color: #fff;
            }
        }
    }
}