.search-line{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #E9E9E999;
    border-radius: 50px;
    transition: all .3s ease;
    @include _1920{
        height: calcFluid(40, 60);
        border-radius: calcFluid(33.33, 50);
    }
    @include _992{
        height: 45px;
        border-radius: 40px;
    }
    &.error{
        border-color: #ff2828;
    }
    &.opened{
        border-radius: 50px 50px 0 0;
        @include _1920{
            border-radius: calcFluid(33.33, 50) calcFluid(33.33, 50) 0 0;
        }
        @include _992{
            border-radius: 40px 40px 0 0 ;
        }
        button{
            border-radius: 0 50px 0 0;
            @include _1920{
                border-radius: 0 calcFluid(33.33, 50) 0 0;
            }
        }
    }
    input{
        flex: 1 0 auto;
        border: none;
        height: 100%;
        padding: 0 34px 0 30px;
        background: transparent;
        font-size: 18px;
        line-height: 22.5px;
        @include _1920{
            padding: 0 calcFluid(22.67, 34) 0 calcFluid(20, 30);
            font-size: calcFluid(12, 18);
            line-height: calcFluid(15, 22.5);
        }
        @include _992{
            padding: 0 22px;
            font-size: 12px;
            line-height: 15px;
        }
        &::placeholder{
            font-size: 18px;
            line-height: 22.5px;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
            @include _992{
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
    button{
        border: none;
        width: 79px;
        height: 100%;
        position: relative;
        border-radius: 0 50px 50px 0;
        transition: all .3s ease;
        background: transparent;
        @include _1920{
            width: calcFluid(52.67, 79);
            height: calcFluid(40, 60);
        }
        @include _992{
            width: 40px;
            height: 100%;
        }
        &:hover{
            svg{
                stroke: $main-clr-hover;
            }
        }
        svg{
			width: 27px;
			height: 27px;
			stroke: #636363;
            fill: transparent;
            transition: all .3s ease;
            @include _1920{
    			width: calcFluid(18, 27);
    			height: calcFluid(18, 27);
            }
            @include _992{
                width: 15px;
                height: 15px;
            }
		}
    }
    &__result{
        display: none;
        flex-direction: column;
        position: absolute;
        width: 100%;
        // top: calc(100% + 1px);
        top: 100%;
        background-color: #fff;
        box-shadow: 0px 4px 16px 0px #0000001F;
        z-index: 2;
        @include _992{
            width: calc(100% + 2px);
            left: -1px;
            border: 1px solid #A9A9A9;
            border-top: none;
        }
        &.opened{
            display: block;
        }
        &:empty{
            display: none !important;
            @include _1920{
                display: none!important;
            }
        }
        a{    
            display: inline-block;
            width: 100%;
            padding: 17px 0 20px 30px;
            transition: all .3s ease;
            font-size: 18px;
            line-height: 22.5px;
            @include _1920{
                padding: calcFluid(11.33, 17) 0 calcFluid(13.33, 20) calcFluid(20, 30);
                font-size: calcFluid(12, 18);
            }
            @include _992{
                padding: 15px 22px;
                font-size: 12px;
                line-height: 15px;
            }
            &.not-found{
                &:hover{
                    background-color: #fff;
                    color: $text-clr!important;
                    span{
                        color: $text-clr!important;
                    }
                }
            }
            span{
                transition: all .3s ease;
            }
            &:hover{
                background-color: $main-clr;
                color: #fff;
                span{
                    color: #fff!important;
                }
            }
        }
    }
    &__list{

    }
    &__title{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #282828;
        margin: 0 0 10px;
        @include _1920{
            font-size: calcFluid(10, 15);
            line-height: calcFluid(12, 18);
            margin: 0 0 calcFluid(6.67, 10);
        }
    }
    &__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        @include _1920{
            padding: calcFluid(3.33, 5) 0;
        }
        span{
            font-size: 15px;
            line-height: 18px;
            color: #282828;
            transition: color $tr-time;
            @include _1920{
                font-size: calcFluid(10, 15);
                line-height: calcFluid(12, 18);
            }
        }
        svg{
            height: 12px;
            width: 8px;
            fill: #999999;
            @include _1920{
                height: calcFluid(8, 12);
                width: calcFluid(5.33, 8);
            }
        }
        &:hover{
            span{
                color: $main-clr-hover;
            }
        }
    }
    &__extra{
        border-top: 1px solid #D0D0D0;
        padding: 15px 0 0;
        margin: 30px 0 0;
        @include _1920{
            border-top: calcFluid(0.67, 1) solid #D0D0D0;
            padding: calcFluid(10, 15) 0 0;
            margin: calcFluid(20, 30) 0 0;
        }
    }
}