@import 'vendors/simple-scrollbar';


.content-seo{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        width: 65%;
        @include _992{
            width: 100%;
        }
    }
}

.content-seo-img{
    margin-bottom: 50px;
    @include _1920{
        margin-bottom: calcFluid(33.33, 50);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        display: flex;
        @include _992{
            flex-direction: column;
        }
    }
    .reverse{
        .content-seo-img__text{
            order: 2;
            margin-left: 80px;
            margin-right: 0;
            @include _1920{
                margin-left: calcFluid(53.33, 80);
            }
            @include _992{
                margin-left: 0px;
            }
        }
        .content-seo-img__image{
            order: 1;
        }
    }
    &__text{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 80px;
        @include _1920{
            margin-right: calcFluid(53.33, 80);
        }
        @include _992{
            order: 1;
            margin-right: 0px;
        }
    }
    &__image{
        min-width: 660px;
        height: 100%;
        @include _1920{
            min-width: calcFluid(440, 660);
        }
        @include _992{
            min-width: none;
            width: 100%;
            margin-bottom: 25px;
        }
        img{
            border-radius: 20px;
            height: 100%;
            max-height: 420px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 11px;
            @include _1920{
                border-radius: calcFluid(13.33, 20);
                margin-bottom: calcFluid(7.33, 11);
                max-height: calcFluid(280, 420);
            }
            @include _992{
                margin-bottom: 0;
                border-radius: 20px;
                height: calcFluidMobile(186, 372);
            }
        }
        span{
            font-size: 18px;
            line-height: 22.5px;
            color: #636363;
            margin-left: 10px;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
                margin-left: calcFluid(6.67, 10);
            }
            @include _992{
                font-size: 12px;
                line-height: 15px;
                margin-left: 10px;
            }
        }
    }
}
