.process{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0px;
            grid-template-rows: repeat(3, auto);
            grid-auto-rows: 0px;
            overflow: hidden;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
        &.opened{
            grid-auto-rows: 1fr;
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid #C9C9C9;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            border: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border-radius: 20px;
            margin-bottom: 20px;
        }
        &__image{
            width: 100%;
            height: 330px;
            margin-bottom: 25px;
            @include _1920{
                height: calcFluid(220, 330);
                margin-bottom: calcFluid(16.67, 25);
            }
            @include _992{
                height: calcFluidMobile(166, 230);
                margin-bottom: 20px;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &__title{
            display: flex;
            margin-bottom: 19px;
            padding: 0 45px;
            @include _1920{
                margin-bottom: calcFluid(12.67, 19);
                padding: 0 calcFluid(30, 45);
            }
            @include _992{
                margin-bottom: 18px;
                padding: 0 24px;
            }

            &__number{
                border: 1px solid $main-clr;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 62px;
                max-height: 62px;
                min-width: 62px;
                margin-right: 24px;
                @include _1920{
                    border: calcFluid(0.67, 1) solid $main-clr;
                    border-radius: calcFluid(10, 15);
                    min-height: calcFluid(41.33, 62);
                    max-height: calcFluid(41.33, 62);
                    min-width: calcFluid(41.33, 62);
                    margin-right: calcFluid(16, 24);
                }
                @include _992{
                    border-radius: 7px;
                    min-width: 40px;
                    min-height: 40px;
                    max-height: 40px;
                    margin-right: 12px;
                }
                span{
                    font-size: 27px;
                    line-height: 33.75px;
                    font-weight: 600;
                    @include _1920{
                        font-size: calcFluid(18, 27);
                        line-height: calcFluid(22.50, 33.75);
                    }
                    @include _992{
                        font-size: 18px;
                        line-height: 22.5px;
                    }
                }
            }
            &__text{
                display: flex;
                align-items: center;
                span{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                    @include _992{
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

        }
        &__text{
            padding: 0 40px 40px 45px;
            @include _1920{
                padding: 0 calcFluid(26.67, 40) calcFluid(26.67, 40) calcFluid(30, 45);
            }
            @include _992{
                padding: 0 24px 35px;
            }
            
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &.last{
            .process{ 
                &__wrapper{
                    border-color: $main-clr; 
                }
                &__item{
                    &__title{
                        &__number{
                            background-color: $main-clr;
                            span{
                                color: #fff;
                            }
                        }
                    }
                    &__text{
                        padding: 0 40px 40px 45px;
                        @include _1920{
                            padding: 0 calcFluid(26.67, 40) calcFluid(26.67, 40) calcFluid(30, 45);
                        }
                    }
                    
                    &__button{
                        margin: auto 40px 40px 45px;
                        @include _1920{
                            margin: auto calcFluid(26.67, 40) calcFluid(26.67, 40) calcFluid(30, 45);
                        }
                    }
                }
            }

        }
    }
}
.process-vertical{
    display: grid;
    grid-template-columns: 1fr 2px 1fr;
    column-gap: 80px;
    @include _1920{
        grid-template-columns: 1fr calcFluid(1.33, 2) 1fr;
        column-gap: calcFluid(53.33, 80);
    }
    @include _992{
        display: none;
    }
    &.mobile{
        display: none;
        @include _992{
            display: flex;
            flex-direction: column;
        }
        .process-vertical__column{
            @include _992{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 20px;
            }
            @include _576{
                grid-template-columns: repeat(1, 1fr);
            }

        }
    }
    &__column{
        
        &.left{
            .process-vertical__item{
                border-radius: 20px 0 20px 20px;
                @include _1920{
                    border-radius: calcFluid(13.33, 20) 0 calcFluid(13.33, 20) calcFluid(13.33, 20);
                }
                &::before{
                    right: -97px;
                    @include _1920{
                        right: calcFluid(-64.67, -97);
                    }
                }
                &.active{
                    &::before{
                        right: -102px;
                        @include _1920{
                            right: calcFluid(-68, -102);
                        }
                    }
                }
            }
        }
        &.right{
            padding-top: 196px;
            @include _1920{
                padding-top: calcFluid(130.67, 196);
            }
            .process-vertical__item{
                border-radius: 0 20px 20px 20px;
                @include _1920{
                    border-radius: 0 calcFluid(13.33, 20) calcFluid(13.33, 20) calcFluid(13.33, 20);
                }
                &::before{
                    left: -97px;
                    @include _1920{
                        left: calcFluid(-64.67, -97);
                    }
                }
                &.active{
                    &::before{
                        left: -102px;
                        @include _1920{
                            left: calcFluid(-68, -102);
                        }
                    }
                }
            }

        }
    }
    &__item{
        border: 1px solid #C9C9C9;
        padding: 40px 43px 40px 45px;
        position: relative;
        margin-bottom: 45px;
        @include _1920{
            border: calcFluid(0.67, 1) solid #C9C9C9;
            padding: calcFluid(26.67, 40) calcFluid(28.67, 43) calcFluid(26.67, 40) calcFluid(30, 45);
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            border-radius: 20px;
            margin-bottom: 20px;
            padding: 30px 24px 35px;
        }
        &__step{
            margin-bottom: 16px;
            @include _1920{
                margin-bottom: calcFluid(10.67, 16);
            }
            @include _992{
                margin-bottom: 14px;
            }
            span{
                font-size: 32px;
                line-height: 40px;
                font-weight: 600;
                color: #C9C9C9;
                @include _1920{
                    font-size: calcFluid(21.33, 32);
                    line-height: calcFluid(26.67, 40);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        &__title{
            margin-bottom: 16px;
            @include _1920{
                margin-bottom: calcFluid(10.67, 16);
            }
            @include _992{
                margin-bottom: 14px;
            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }

            }
        }
        &__text{
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__button{
            margin: 0 auto;
            margin-top: 30px;
            width: 51.7%;
            @include _1920{
                margin-top: calcFluid(20, 30);
            }
            @include _992{
                margin-top: 30px;
                width: 100%;
            }
        }
        &.active{
            .process-vertical__item__step{
                span{
                    color: $main-clr;
                }
            }
            &::before{
                background-color: $main-clr;
                width: 40px;
                height: 40px;
                @include _1920{
                    width: calcFluid(26.67, 40);
                    height: calcFluid(26.67, 40);
                }
            }
        }
        &.last-item{
            grid-column: 1/-1;
            width: 56.5%;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            @include _1920{
                border-radius: calcFluid(13.33, 20);
            }
            @include _992{
                width: 100%;
                border-radius: 20px;
            }
            &::before{
                background-color: transparent;
            }
        }
        &::before{
            content: '';
            width: 30px;
            height: 30px;
            top: -20px;
            background-color: #C9C9C9;
            display: block;
            position: absolute;
            z-index: 2;
            border-radius: 10px;
            transition: all .3s ease;
            @include _1920{
                width: calcFluid(20, 30);
                height: calcFluid(20, 30);
                top: calcFluid(-13.33, -20);
                border-radius: calcFluid(6.67, 10);
            }
            @include _992{
                display: none;
            }
        }
    }
    &__scroll{
        background-color: #C9C9C9;
        width: 100%;
        position: relative;
        overflow: hidden;
        span{
            position: absolute;
            display: block;
            background-color: $main-clr;
            width: 100%;
            height: 0px;
            top: 0px;
        }

    }
}
.process-icon{
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            row-gap: 20px;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #F5F5F5;
        padding: 34px 40px 49px 40px;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            padding: calcFluid(22.67, 34) calcFluid(26.67, 40) calcFluid(32.67, 49) calcFluid(26.67, 40);
        }
        @include _992{
            border-radius: 20px;
            padding: 30px 24px 35px;
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                width: 50%;
                margin: 0 auto;
                grid-column: 1/-1;
            }
            @include _576{
                width: 100%;
            }
        }
        &__icon{
            width: 100px;
            height: 100px;
            margin-bottom: 24px;
            @include _1920{
                width: calcFluid(66.67, 100);
                height: calcFluid(66.67, 100);
                margin-bottom: calcFluid(16, 24);
            }
            @include _992{
                width: 40px;
                height: 40px;
                margin-bottom: 18px;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        &__title{
            margin-bottom: 21px;
            width: 90%;
            @include _1920{
                margin-bottom: calcFluid(14, 21);
            }
            @include _992{
                margin-bottom: 14px;
            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__text{
            span{
                font-size: 21px;
                line-height: 32px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21.33, 32);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &--form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 20px;
            border: 1px solid $main-clr;
            padding: 15px 40px 34px;
            @include _1920{
                border-radius: calcFluid(13.33, 20);
                border: calcFluid(0.67, 1) solid $main-clr;
                padding: calcFluid(10, 15) calcFluid(26.67, 40) calcFluid(22.67, 34);
            }
            @include _992{
                border-radius: 20px;
                padding: 20px 25px 35px;
            }
            &__title{
                text-align: center;
                margin-bottom: 30px;
                @include _1920{
                    margin-bottom: calcFluid(20, 30);
                }
                @include _992{
                    margin-bottom: 20px;
                }
                span{
                    font-size: 48px;
                    line-height: 55.2px;
                    @include _1920{
                        font-size: calcFluid(32, 48);
                        line-height: calcFluid(36.80, 55.2);
                    }
                    @include _992{
                        font-size: 26px;
                        line-height: 32px;
                    }
                }
            }
            &__button{
                width: 61%;
                margin: 0 auto;
                @include _992{
                    width: 100%;
                }
                span{

                }
            }

        }
    }
}
.process-image-icon{
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 45px;
        column-gap: 45px;
        @include _1920{
            row-gap: calcFluid(30, 45);
            column-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        border: 1px solid #C9C9C9;
        position: relative;
        transition: box-shadow .3s ease, border-color .3s ease;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            border: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border-radius: 20px;
        }
        &__image{
            position: relative;
            margin-bottom: 18px;
            @include _1920{
                margin-bottom: calcFluid(12, 18);
            }
            @include _992{
                margin-bottom: 14px;
            }
            &__img{
                width: 100%;
                height: 254px;
                object-fit: cover;
                border-radius: 20px 20px 0 0 ;
                @include _1920{
                    height: calcFluid(169.33, 254);
                    border-radius: calcFluid(13.33, 20) calcFluid(13.33, 20) 0 0;
                }
                @include _992{
                    height: 180px;
                    border-radius: 20px 20px 0 0;
                }
            }
            &__ico{
                position: absolute;
                bottom: 10px;
                right: 10px;
                width: 70px;
                height: 70px;
                border-radius: 20%;
                @include _1920{
                    bottom: calcFluid(6.67, 10);
                    right: calcFluid(6.67, 10);
                    width: calcFluid(46.67, 70);
                    height: calcFluid(46.67, 70);
                }
                @include _992{
                    bottom: 10px;
                    right: 10px;
                    width: 50px;
                    height: 50px;
                }
            }
            &__step{
                position: absolute;
                left: 0px;
                top: 25px;
                background-color: #FFFBD4;
                @include _1920{
                    top: calcFluid(16.67, 25);
                }
                @include _992{
                    top: 30px;
                }
                span{
                    display: block;
                    position: relative;
                    padding: 9px 2px 10px 16px;
                    z-index: 1;
                    font-size: 21px;
                    line-height: 26.25px;
                    @include _1920{
                        padding: calcFluid(6, 9) calcFluid(1.33, 2) calcFluid(6.67, 10) calcFluid(10.67, 16);
                        font-size: calcFluid(14, 21);
                        line-height: calcFluid(17.50, 26.25);
                    }
                    @include _992{
                        padding: 9px 2px 10px 16px;
                        font-size: 16px;
                        line-height: 20px;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        display: block;
                        background-color: #FFFBD4;
                        width: 32px;
                        height: 32px;
                        transform: rotate(45deg);
                        top: 7px;
                        right: -16px;
                        z-index: -1;
                        @include _1920{
                            width: calcFluid(21.33, 32);
                            height: calcFluid(21.33, 32);
                            top: calcFluid(4.67, 7);
                            right: calcFluid(-10.67, -16);
                        }
                        @include _992{
                            width: 27px;
                            height: 27px;
                            top: 6px;
                            right: -14px;
                        }
                    }
                }
            }
        }
        &__title{
            text-align: center;
            margin-bottom: 18px;
            @include _1920{
                margin-bottom: calcFluid(12, 18);
            }
            @include _992{
                margin-bottom: 6px;
            }
            span{
                font-size: 27px;
                line-height: 33.75px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        &__text{
            position: absolute;
            background-color: #fff;
            box-shadow: 0px 16px 24px 0px #0000002E;
            top: 100%;
            max-height: 0px;
            overflow: hidden;
            transition: all .3s ease;
            width: calc(100% + 2px);
            margin-left: -1px;
            z-index: 1;
            border-radius: 0 0 20px 20px;
            @include _1920{
                margin-left: calcFluid(-0.67, -1);
                border-radius: 0 0 calcFluid(13.33, 20) calcFluid(13.33, 20);
            }
            @include _992{
                margin-left: 0px;
                border-radius: 0 0 20px 20px;
                max-height: 100%;
                position: initial;
                box-shadow: none;
                width: 100%;
            }
            span{
                display: flex;
                padding: 0 35px 45px 40px;
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    padding: 0 calcFluid(23.33, 35) calcFluid(30, 45) calcFluid(26.67, 40);
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    padding: 0 24px 35px;
                    font-size: 16px;
                    line-height: 24px;
                }

            }
        }
        &:hover{
            border-color: transparent;
            border-radius: 20px 20px 0 0;
            box-shadow: 0px 2px 24px 0px #0000002E;
            @include _1920{
                border-radius: calcFluid(13.33, 20) calcFluid(13.33, 20) 0 0;
                box-shadow: 0 calcFluid(1.33, 2) calcFluid(16, 24) 0 #0000002E;
            }
            @include _992{
                border-color: #C9C9C9;
                box-shadow: none;
            }

            .process-image-icon__item{
                &__text{
                    max-height: 100%;
                }
            }
        }
        
        &.last{
            .process-image-icon__item__image{
                &__step{
                    background-color: $main-clr;
                    span{
                        color: #fff;
                        &::after{
                            content: '';
                            position: absolute;
                            background-color: $main-clr;
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                            top: 0px;
                            right: -20px;
                            @include _1920{
                                width: calcFluid(30, 45);
                                height: calcFluid(30, 45);
                                right: calcFluid(-13.33, -20);
                            }
                            @include _992{
                                width: 39px;
                                height: 39px;
                                right: -15px;
                            }
                        }
                    }
                }

            }
        }
}
}