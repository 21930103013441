.big-link{
    &__container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 45px;
        column-gap: 45px;
        @include _1920{
            row-gap: calcFluid(30, 45);
            column-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 10px;
            grid-auto-rows: 1fr;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        display: flex;
        align-items: end;
        position: relative;
        padding: 65px;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        @include _1920{
            padding: calcFluid(43.33, 65);
            height: calcFluid(200, 300);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            padding: 12px 32px 16px 26px;
            height: auto;
            border-radius: 20px;
            justify-content: space-between;
            align-items: center;
        }
        span{
            font-size: 24px;
            line-height: 30px;
            font-weight: 600;
            transition: color .3s ease;
            position: relative;
            z-index: 1;
            transition: all .3s ease;
            @include _1920{
                font-size: calcFluid(16, 24);
                line-height: calcFluid(20, 30);
            }
            @include _992{
                font-size: 21px;
                line-height: 26.25px;
                max-width: 85%;
            }
        }
        svg{
            position: absolute;
            height: 20px;
            width: 20px;
            top: 25px;
            z-index: 2;
            right: 25px;
            transition: all .3s ease;
            @include _1920{
                height: calcFluid(13.33, 20);
                width: calcFluid(13.33, 20);
                top: calcFluid(16.67, 25);
                right: calcFluid(16.67, 25);
            }
            @include _992{
                position: initial;
                height: 17px;
                width: 17px;
                stroke: #272727;
            }
        }
        &::after{
            display: block;
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: #E9E9E9D9;
            transition: all .3s ease;
        }
        &:hover{
            svg{
                stroke: #fff;
            }
            span{
                color: #fff;
            }
            &::after{
                background-color: $main-clr;
            }
            .big-link{
                &::after{
                    background-color: $main-clr;
                }
            }
        }
    }
}