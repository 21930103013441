.container{
	max-width: 1755px;
	margin: 0 auto;
	@include _1920{
    	max-width: calcFluid(1170, 1755);
	}
}

#page-container{
	// padding: 0 0 100px;
	// @include _1920{
    // 	padding: 0 0 calcFluid(66.67, 100);
	// }
	// @include _992{
	// 	padding: 0 0 60px;
	// 	@include _1920{
    // 		padding: 0 0 calcFluid(40, 60);
	// 	}
	// }
}
.block-space{
	margin-bottom: 100px!important;
	&:last-child{
		&.banner-form{
			margin-bottom: 0!important;
		}
	}
	@include _1920{
    	margin-bottom: 0 0 calcFluid(66.67, 100)!important;
	}
	@include _992{
		margin-bottom: 0 0 60px!important;
	}
	@include _576{
		margin-bottom: 0 0 calcFluid(40, 60)!important;
	}
}

em{
	font-style: normal;
	color: $main-clr-hover;
}

.loading{
	// opacity: .4;
	filter: blur(3px);
	pointer-events: none;
	user-select: none;
}
.mobile-btn-collapse{
	margin-top: 30px;
}
.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;

	&--icon{
		background-color: transparent;
		transition: fill $tr-time;
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
		svg{
			width: 25px;
			height: 20px;
			fill: #B6B6B6;
			@include _1920{
    			width: calcFluid(16.67, 25);
    			height: calcFluid(13.33, 20);
			}
		}
	}

	&--filled{
		transition: background-color $tr-time;
		background-color: $main-clr;
		border-radius: 50px;
		height: 74px;
		@include _1920{
    		border-radius: calcFluid(33.33, 50);
    		height: calcFluid(49.33, 74);
		}
		@include _992{
			border-radius: 40px;
			height: 52px;
		}
		span{
			font-weight: 500;
			font-size: 21px;
			line-height: 26.25px;
			color: #fff;
			@include _1920{
    			font-size: calcFluid(14, 21);
    			line-height: calcFluid(17.50, 26.25);
			}
			@include _992{
				font-size: 16px;
				line-height: 20px;
			}
		}
        svg{
            fill: #fff;
            width: 32px;
            height: 32px;
            margin-right: 5px;
            @include _1920{
                width: calcFluid(21.33, 32);
                height: calcFluid(21.33, 32);
                margin-right: calcFluid(3.33, 5);
            }
        }
		
		&:hover{
			background-color: $main-clr-hover;
		}
	}

	&--reverse{
		background-color: #fff;
		border: 1px solid #EC1C24;
		border-radius: 50px;
		color: #EC1C24;
		height: 74px;
		transition: all .3s ease;
		@include _1920{
    		border: calcFluid(0.67, 1) solid #EC1C24;
    		border-radius: calcFluid(33.33, 50);
    		height: calcFluid(49.33, 74); 
		}
		@include _992{
			border-radius: 40px;
			height: 52px;
		}
		span{
			font-size: 21px;
			line-height: 26.25px;
			color: #EC1C24;
			transition: color .3s ease;
			text-align: center;
			@include _1920{
    			font-size: calcFluid(14, 21);
    			line-height: calcFluid(17.50, 26.25);
			}
			@include _992{
				font-size: 16px;
				line-height: 20px;
			}
		}
		&:hover{
			background-color: $main-clr-hover;
			border-color: $main-clr-hover;
			span{
				color: #fff;
			}
			svg{
				fill: #fff;
			}
		}
	}

	&--black{
		background-color: $alt-clr;
		transition: background-color $tr-time;
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: #fff;
			@include _1920{
    			font-size: calcFluid(10, 15);
    			line-height: calcFluid(12, 18);
			}
		}
		&:hover{
			background: #343434;
		}
	}

	&--border{
		border: 1px solid #282828;
		@include _1920{
    		border: calcFluid(0.67, 1) solid #282828;
		}
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: #282828;
			@include _1920{
    			font-size: calcFluid(10, 15);
    			line-height: calcFluid(12, 18);
			}
		}
	}

	&--gray{
		background-color: #FFFFFF;
		border: 1px solid #D0D0D0;
		transition: border-color $tr-time;
		@include _1920{
    		border: calcFluid(0.67, 1) solid #D0D0D0;
		}
		span{
			font-size: 15px;
			line-height: 18px;
			color: #282828;
			@include _1920{
    			font-size: calcFluid(10, 15);
    			line-height: calcFluid(12, 18);
			}
		}
		&:hover{
			border-color: $main-clr-hover;
		}
	}

	&--poly{
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		background-color: #282828;
		font-weight: 500 !important;
		font-size: 12px !important;
		line-height: 14px !important;
		color: #FFFFFF !important;
		width: 22px;
		height: 20px;
		transform: scale(1);
		transition: transform $tr-time;
		@include _1920{
    		font-weight: 500!important;
    		font-size: calcFluid(8, 12)!important;
    		line-height: calcFluid(9.33, 14)!important;
    		color: #FFFFFF!important;
    		width: calcFluid(14.67, 22);
    		height: calcFluid(13.33, 20);
		}
		&:empty{
			transform: scale(0);
		}
	}

	&--swal{
		padding: 15px 20px;
		margin: 0 5px;
		@include _1920{
    		padding: calcFluid(10, 15) calcFluid(13.33, 20);
    		margin: 0 calcFluid(3.33, 5);
		}
	}
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}
.content{
	padding: 0; 
}


.js-open-other{
	&.opened{
		.js-hidden{
			display: block;
		}
	}
	.js-hidden{
		display: none;
	}
}


.open-other{
	cursor: pointer;
	margin: 20px 0 0;
	@include _1920{
    	margin: calcFluid(13.33, 20) 0 0;
	}
	&:hover{
		span{
			border-color: transparent;
		}
	}
	span{
		font-size: 15px;
		line-height: 18px;
		border-bottom: 1px solid #999999;
		color: #999999;
		transition: border-color $tr-time;
		@include _1920{
    		font-size: calcFluid(10, 15);
    		line-height: calcFluid(12, 18);
    		border-bottom: calcFluid(0.67, 1) solid #999999;
		}
	}
}

.lead-phone{
	svg{
		width: 15px;
		height: 15px;
		fill: $main-clr-hover;
		margin-right: 8px;
		@include _1920{
    		width: calcFluid(10, 15);
    		height: calcFluid(10, 15);
    		margin-right: calcFluid(5.33, 8);
		}
	}
	span{
		font-size: 15px;
		line-height: 18px;
		color: $text-clr;
		@include _1920{
    		font-size: calcFluid(10, 15);
    		line-height: calcFluid(12, 18);
		}
	}
}

.breadcrumbs{
	margin-top: 35px;
	padding: 0 0 60px;
	@include _1920{
    	padding: 0 0 calcFluid(40, 60);
    	margin-top: calcFluid(23.33, 35);
	}
	@include _992{
		padding:  0 0 30px;
		margin-top: 0px;
	}
	&-item{
		font-size: 18px;
		line-height: 22.5px;
		color: #A9A9A9;
		@include _1920{
    		font-size: calcFluid(12, 18);
    		line-height: calcFluid(15, 22.5);
		}
		@include _992{
			font-size: 12px;
			line-height: 15px;
		}
	}
	&-wrapper{
		a{
			color: $main-clr;
			&:hover{
				color: $main-clr-hover;
			}
		}
	}
	&-back{
		display: block;
		margin: 15px 0 0;
		padding: 10px 0;
		@include _1920{
    		margin: calcFluid(10, 15) 0 0;
    		padding: calcFluid(6.67, 10) 0;
		}
		&:hover{ 
			svg{
				fill: $main-clr-hover;
			}
			span{ 
				color: $main-clr-hover;
			}
		}
		svg{
			width: 16px;
			height: 10px;
			fill: $text-clr;
			margin-right: 5px;
			transition: fill $tr-time;
			@include _1920{
    			width: calcFluid(10.67, 16);
    			height: calcFluid(6.67, 10);
    			margin-right: calcFluid(3.33, 5);
			}
		}
		span{
			font-weight: 500;
			font-size: 15px;
			line-height: 18px;
			color: $text-clr;
			transition: color $tr-time;
			@include _1920{
    			font-size: calcFluid(10, 15);
    			line-height: calcFluid(12, 18);
			}
		}
	}
}
.static-banner{
	height: 385px;
	position: relative;
    display: flex;
    flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
	@include _1920{
		height: calcFluid(256.67, 385);
	}
	@include _992{
		height: calcFluidMobile(151, 240);
		padding-top: 10px;
	}

	&::before{
		content: '';
		display: block;
		background-color: #fff;
		opacity: .8;
		top: 0;
		left: 0;
		width: 100%; 
		height: 100%;
		position: absolute;
	}
	&__title{
		z-index: 1;
		padding: 0 75px;
		@include _1920{
			padding: 0 calcFluid(50,75);
		}
		@include _992{
			padding: 0 21px;
		}
		span{
			font-size: 48px;
			line-height: 55.2px;
			font-weight: 700;
			@include _1920{
				font-size: calcFluid(32, 48);
				line-height: calcFluid(36.8, 55.2);
			}
			@include _992{
				font-size: 20px;
				line-height: 25px;
			}
		}
	}
	.breadcrumbs{
		z-index: 1;
	}
}
.page-title{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	// margin: 0 0 35px;
	@include _1920{
    	// margin: 0 0 calcFluid(23.33, 35);
	}
	@include _992{
	}
	&--line{
		align-items: center;
		justify-content: space-between;
	}
	&--notify{
		flex-direction: column;
	}
	&__name{
		margin-right: 15px;
		@include _1920{
    		margin-right: calcFluid(10, 15);
		}
		@include _992{
			margin-right: 0px;
			
		}
		span{
			font-weight: 600;
			font-size: 48px;
			line-height: 55px;
			color: $text-clr;
			@include _1920{
    			font-size: calcFluid(32, 48);
    			line-height: calcFluid(36.67, 55);
			}
			@include _992{
				font-size: 20px;
				line-height: 25px;
			}
		}
	}
	&__link{
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: $text-clr;
		position: relative;
		transition: color $tr-time;
		@include _1920{
    		font-size: calcFluid(12, 18);
    		line-height: calcFluid(14.67, 22);
		}
		&:hover{
			color: $main-clr-hover;
		}
		&::before{
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background-color: $main-clr-hover;
			position: absolute;
			border-radius: 100%;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto;
			@include _1920{
    			width: calcFluid(4, 6);
    			height: calcFluid(4, 6);
    			left: calcFluid(-10, -15);
			}
		}
	}
	&__content{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}
	&__notify{
		margin: 15px 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include _1920{
    		margin: calcFluid(10, 15) 0 0;
		}
		@include _768{
			flex-direction: column;
    		align-items: flex-start;
		}
		&-text{
			font-size: 15px;
			line-height: 24px;
			color: #282828;
			@include _1920{
    			font-size: calcFluid(10, 15);
    			line-height: calcFluid(16, 24);
			}
		}
		&-link{
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-decoration-line: underline;
			color: $main-clr-hover;			
			@include _1920{
    			font-size: calcFluid(10.67, 16);
    			line-height: calcFluid(16, 24);
			}
		}
	}
}
.custom-form{
	&__input-list{
		width: 100%;
		display: flex;
		align-items: center;
		border: 1px solid #A9A9A9;
		border-bottom: transparent;
		border-radius: 35px;
		margin-bottom: 10px;
		position: relative;
		transition: all .05s ease-in-out;
		transition-delay: .15s;
		@include _1920{
    		border: calcFluid(0.67, 1) solid #A9A9A9;
    		border-radius: calcFluid(23.33, 35);
    		margin-bottom: calcFluid(6.67, 10);
		}
		@include _992{
			border-radius: 40px;
			margin-bottom: 12px;
		}
		input{
			border: none;
			background-color: transparent;
			width: 100%;
			height: 68px;
			padding: 0 34px;
			@include _1920{
    			height: calcFluid(45.33, 68);
    			padding: 0 calcFluid(22.67, 34);
			}
			@include _992{
				height: 50px;
				padding: 0 24px;
			}
		}
		svg{
			width: 14px;
			height: 14px;
			fill: #636363;
			margin-right: 37px;
			@include _1920{
    			width: calcFluid(9.33, 14);
    			height: calcFluid(9.33, 14);
    			margin-right: calcFluid(24.67, 37);
			}
			@include _992{
				width: 11px;
				height: 11px;
				margin-right: 20px;
			}
		}
		&.error{
			border-color: #ff0000;
			.custom-form__dropdown{
				border-color: #ff0000;
			}
		}
		&.open{
			// border-bottom: 1px solid transparent;
			border-radius: 35px 35px 0 0;
			transition-delay: .08s;
			transition: all .05s ease-in-out;
			@include _1920{
    			// border-bottom: calcFluid(0.67, 1) solid transparent;
    			border-radius: calcFluid(23.33, 35) calcFluid(23.33, 35) 0 0;
			}
			@include _992{
				border-radius: 27px 27px 0 0;
			}
			.custom-form__dropdown{
				max-height: 400px;
				@include _1920{
    				max-height: calcFluid(266.67, 400);
				}
				@include _992{
					max-height: 300px;
				}
			}
		}
	}
	&__half-input-container{
		width: 100%;
		display: flex;
	}
	&__half-input{
		width: 100%;
		border: 1px solid #A9A9A9;
		margin-bottom: 10px;
		@include _1920{
    		border: calcFluid(0.67, 1) solid #A9A9A9;
    		margin-bottom: calcFluid(6.67, 10);
		}
		@include _992{
			margin-bottom: 12px;
		}
		&.error{
			border-color: #ff0000;
		}
		&.left{
			border-radius: 50px 0 0 50px;
			margin-right: 10px;
			@include _1920{
    			border-radius: calcFluid(33.33, 50) 0 0 calcFluid(33.33, 50);
    			margin-right: calcFluid(6.67, 10);
			}
			@include _992{
				border-radius: 40px 0 0 40px;
				margin-right: 10px;
				input{
					padding: 0 0 0 24px;
				}
			}
		}
		&.right{
			border-radius: 0 50px 50px 0;
			@include _1920{
    			border-radius: 0 calcFluid(33.33, 50) calcFluid(33.33, 50) 0;
			}
			@include _992{
				border-radius:  0 40px 40px 0;
			}
			input{
				text-align: end;
				padding: 0 24px 0 0;
			}
		}
		input{
			border: none;
			background-color: transparent;
			width: 100%;
			height: 68px;
			padding: 0 34px;
			@include _1920{
    			height: calcFluid(45.33, 68);
    			padding: 0 calcFluid(22.67, 34);
			}
			@include _992{
				height: 50px;
			}
		}
	}
	&__input-wrapper{
		display: flex;
		flex-direction: column;
	}
	&__input{
		display: flex;
		width: 100%;
		border: 1px solid #A9A9A9;
		border-radius: 50px;
		margin-bottom: 10px;
		&.error{
			border-color: #ff0000;
		}
		@include _1920{
    		border: calcFluid(0.67, 1) solid #A9A9A9;
    		border-radius: calcFluid(33.33, 50);
    		margin-bottom: calcFluid(6.67, 10);
		}
		@include _992{
			border-radius: 40px;
			margin-bottom: 12px;
		}
		input{
			border: none;
			background-color: transparent;
			width: 100%;
			height: 68px;
			padding: 0 34px;
			@include _1920{
    			height: calcFluid(45.33, 68);
    			padding: 0 calcFluid(22.67, 34);
			}
			@include _992{
				height: 52px;
				padding: 0 21px;
			}
		}

	}
	&__dropdown{
		position: absolute;
		top: 30px;
		overflow: hidden;
		max-height: 39px;
		width: calc(100% + 2px);
		left: -1px;
		transition: all .3s ease;
		border: 1px solid #A9A9A9;
		border-radius: 0 0 35px 35px;
		border-top: none;
		@include _1920{
    		top: calcFluid(20, 30);
    		max-height: calcFluid(26, 39);
    		border: calcFluid(0.67, 1) solid #A9A9A9;
			border-top: none;
    		border-radius: 0 0 calcFluid(23.33, 35) calcFluid(23.33, 35);
		}
		@include _992{
			top: 22px;
			max-height: 29px;
			border-radius: 0 0 26px 26px;
		}
		&__wrapper{
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		&__item{
			width: 100%;
			padding: 10px 35px;
			background-color: #fff;
			transition: all .3s ease;
			cursor: pointer;
			@include _1920{
    			padding: calcFluid(6.67, 10) calcFluid(23.33, 35);
			}
			@include _992{
				padding: 5px 24px;
			}
			span{
				font-size: 21px;
			 	line-height: 26.25px;	
			 	@include _1920{
    				font-size: calcFluid(14, 21);
    			 	line-height: calcFluid(17.50, 26.25);
			 	}
				@include _992{
					font-size: 16px;
					line-height: 20px;
				}
			}
			&:hover{
				background-color: rgba(245, 245, 245, 1);
			}
		}
		&__fake-item{
			background-color: transparent;
			height: 38px;
			@include _1920{
    			height: calcFluid(25.33, 38);
			}
			@include _992{
				height: 24px;
			}

		}
	}
	&__personal{
		text-align: center;
		span{
			display: block;
			font-size: 18px;
			line-height: 22.5px;
			color: #636363;
			@include _1920{
    			font-size: calcFluid(12, 18);
    			line-height: calcFluid(15, 22.5);
			}
			@include _992{
				font-size: 12px;
				line-height: 15px;
			}
			a{
				color: #272727;
				border-bottom: 1px solid #272727;
				@include _1920{
    				border-bottom: calcFluid(0.67, 1) solid #272727;
				}
				&:hover{
					border-color: transparent;
				}
			}
		}
	}
	&__button{
		margin-bottom: 10px;
		@include _1920{
    		margin-bottom: calcFluid(6.67, 10);
		}
	}
	&__hidden{
		display: none;

	}
	&__title{
		font-size: 18px;
		line-height: 27px;
		margin-bottom: 16px;
		margin-top: 20px;
		@include _1920{
			font-size: calcFluid(12, 18);
			line-height: calcFluid(18, 27);
			margin-bottom: calcFluid(10.67, 16);
			margin-top: calcFluid(13.33, 20);
		}
		@include _992{
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 12px;
			margin-top: 30px;
		}
	}
	&__file{
		display: inline-flex;
		padding: 8px 18px;
		border-radius: 30px;
		border: 1px solid $main-clr;
		width: fit-content;
		margin-bottom: 3px;
		cursor: pointer;
		transition: all .3s ease;
		margin-right: 13px;
		@include _1920{
			padding: clacFluid(5.3, 8) calcFluid(12, 18);
			border-radius: calcFluid(20, 30);
			margin-bottom: calcFluid(2, 3);
			margin-right: calcFluid(8.3, 13);
		}
		@include _992{
			padding: 9px 0 10px;
			width: 100%;
			border-radius: 30px;
			margin-bottom: 12px;
			margin-right: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&:hover{
			background-color: $main-clr-hover;
			border-color: $main-clr-hover;
			span{
				color: #fff;
				@include _992{
					color: $text-clr;
				}
			}
			svg{
				fill: #fff;
				@include _992{
					fill: $main-clr;
				}
			}
			@include _992{
				background-color: #fff;
				border-color: $main-clr;
			}
		}
		svg{
			height: 22px;
			width: 22px;
			fill: $main-clr;
			margin-right: 14px;
			transition: fill .3s ease;
			@include _1920{
				height: calcFluid(14.3, 22);
				width: calcFluid(14.3, 22);
			}
			@include _992{
				height: 20px;
				width: 20px;
			}
		}
		span{
			font-size: 14px;
			line-height: 21px;
			color: #333;
			transition: color .3s ease;
			@include _1920{
				font-size: calcFluid(8.67, 14);
			}
			@include _992{
				font-size: 14px;
				line-height: 21px;
			}
		}
		&__item{
			display: inline-flex;
			margin-right: 20px;
			align-items: center;
			@include _1920{
				margin-right: calcFluid(13.33, 20);
			}
			@include _992{
				margin-right: 0;
			}
		}
		&__name{
			font-size: 14px;
			line-height: 21px;
			margin-right: 10px;
			@include _1920{
				font-size: calcFluid(8.67, 14);
				line-height: calcFluid(14, 21);
				margin-right: calcFluid(6.67, 10);
			}
			@include _992{
				font-size: 12px;
				line-height: 15px;
				margin-right: 8px;
			}
		}
		&__close{
			font-size: 22px!important;
			transition: color .3s ease;
			cursor: pointer;
			@include _1920{
				font-size: calcFluid(14.67, 22)!important;
			}
			@include _992{
				font-size: 18px!important;
			}
			&:hover{
				color: $main-clr-hover;
			}
		}

	}
	&__load{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 30px;
		@include _1920{
			margin-bottom: calcFluid(20, 30);
		}
		@include _992{
			margin-bottom: 12px;
		}
		
		&__image{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 5px;
			@include _1920{
				margin-right: calcFluid(3.3, 5);
			}
			@include _992{
				margin-right: 10px;
			}
			img{
				border-radius: 6px;
				height: 39px;
				width: 67px;
				@include _1920{
					border-radius: calcFluid(4, 6);
					height: calcFluid(26, 39);
					width: calcFluid(44.67, 67);
				}
				@include _992{
					border-radius: 6px;
					height: 36px;
					width: 62px;
				}
			}
		}
		&__close{
			font-size: 20px!important;
    		margin-top: 5px;
			transition: color .3s ease;
			cursor: pointer;
			@include _1920{
				font-size: calcFluid(13.33, 20)!important;
			}
			@include _992{
				font-size: 15px!important;
			}
			&:hover{
				color: $main-clr-hover;
				@include _992{
					color: #272727;
				}
			}
		}
		
	}
	&__load-count{
		@include _992{
			text-align: end;
		}
		
		span{
			font-size: 14px;
			line-height: 21px;
			color: #333;
		}
	}
	input{
		@include _992{
			font-size: 16px;
			line-height: 20px;
		}
		&::placeholder{

			@include _992{
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
}

.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-nav{
		cursor: pointer;
		&--next{
			svg{
				transform: rotate(180deg);
			}
		}
		svg{
			width: 36px;
			height: 25px;
			fill: #999999;
			transition: fill $tr-time;
			@include _1920{
    			width: calcFluid(24, 36);
    			height: calcFluid(16.67, 25);
			}
		}
		&:hover{
			svg{
				fill: $alt-clr;
			}
		}
	}
	&-pagination{
		z-index: 1;
		&-bullet{
			margin: 0 10px;
			width: 10px;
			height: 10px;
			background-color: #6A6A6A;
			opacity: 1;
			transition: background-color $tr-time;
			@include _1920{
    			margin: 0 calcFluid(6.67, 10);
    			width: calcFluid(6.67, 10);
    			height: calcFluid(6.67, 10);
			}
			&-active{
				background-color: $main-clr;
			}
			@include _576{
				width: 8px;
				height: 8px;
				margin: 0 3px;
				@include _1920{
    				width: calcFluid(5.33, 8);
    				height: calcFluid(5.33, 8);
    				margin: 0 calcFluid(2, 3);
				}
			}
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.theme-counter{
    cursor: pointer;
    display: grid;
    grid-template-columns: 26px 1fr 26px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    @include _1920{
        grid-template-columns: calcFluid(17.33, 26) 1fr calcFluid(17.33, 26);
        border: calcFluid(0.67, 1) solid #D0D0D0;
    }
    &__minus,
    &__plus{
		user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
			width: 13px;
			height: 13px;
			fill: $text-clr;
			transition: fill $tr-time;
			@include _1920{
    			width: calcFluid(8.67, 13);
    			height: calcFluid(8.67, 13);
			}
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
    }
    &__field{
        border: none;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: $text-clr;
        @include _1920{
            font-size: calcFluid(12, 18);
            line-height: calcFluid(17.33, 26);
        }
    }

}


.right-btn{
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	right: -129px;
	top: calc(60% - 160px);
	width: 320px;
	height: 74px;
	transform: rotate(-90deg);
	background-color: $main-clr;
	border-radius: 40px 40px 0 0;
	cursor: pointer;
	transition: background-color .3s ease;
	@include _1920{
		right: calcFluid(-86, -129);
		top: calc(60% - 107px);
		width: calcFluid(213.33, 320);
		height: calcFluid(49.33, 74);
		border-radius:  calcFluid(26.67, 40) calcFluid(26.67, 40) 0 0;
	}
	@include _992{
		display: none;
	}
	&:hover{
		background-color: $main-clr-hover;
	}
	span{
		font-size: 21px;
		line-height: 26.25px;
		color: #fff;
		@include _1920{
			font-size: calcFluid(14,21);
			line-height: calcFluid(17.5, 26.25);
		}
	}
	svg{
		height: 36px;
		width: 36px;
		margin-right: 11px;
		fill: #fff;
		@include _1920{
			height: calcFluid(24, 36);
			width: calcFluid(24, 36);
			margin-right: calcFluid(7.33, 11);
		}
	}
}
.swal2-title{
	@include _576{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _576{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _576{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}
.text-blink{
	background: linear-gradient(to right,#fff 40%,#ec1c24 50%,#ec1c24 50%,#fff 70%);
	background-size: 200% auto;
	color: #fff;
	font-weight: 500;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: blink 1s linear infinite;
}
@keyframes blink{
	from {
		background-position: 200% center;
	}
	to {
		background-position: 0% center;
	}
}


.select_city{

	&--desktop_notify, &--mobile_notify{

		width: fit-content;
		height: fit-content;
		padding: 20px;

		background: #FFFFFF;
		box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.18);
		border-radius: 20px;

		.notify--wrapper{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		.notify--top{

			padding-left: 50px;
			padding-right: 50px;

			width: 100%;
			padding-bottom: 18px;
			margin-bottom: 22px;
			border-bottom: 1px solid #C9C9C9;
			position: relative;

			.top--question{
				font-size: 21px;
				line-height: 150%;
				text-align: center;
				color: #272727;

				.question--city{
					font-weight: 700;
					font-size: 21px;
					line-height: 150%;
					color: #272727;

					@include _1920{
						font-size: calcFluidAll(16, 21);
					}

					@include _1200{
						font-weight: normal;
					}
				}

				@include _1920{
					font-size: calcFluidAll(16, 21);
				}

			}

			.top--exit{
				cursor: pointer;
				position: absolute;
				width: 14px;
				height: 14px;
				right: 0;
				top: 0;
				svg{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					fill: #333333;
					stroke: transparent;
				}
			}
		}

		.notify--bottom{
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: center;

			@include _1920{
				height: calcFluidAll(40, 50);
			}

			.btn{
				height: 100%;
			}

			.bottom--agree{
				padding-left: 30px;
				padding-right: 30px;

				font-size: 18px;
				line-height: 125%;
				color: #FFFFFF;

				margin-right: 20px;


				@include _1920{
					font-size: calcFluidAll(14, 18);
					padding-left: calcFluidAll(22, 28);
					padding-right: calcFluidAll(22, 28);
					margin-right: calcFluidAll(10, 20);
				}

			}

			.bottom--another{
				padding-left: 28px;
				padding-right: 28px;

				font-size: 18px;
				line-height: 125%;
				color: #272727;
				border-color: #000000;

				&:hover{
					color: white;
					background-color: #000000;
					border-color: #000000;
				}

				@include _1920{
					font-size: calcFluidAll(14, 18);
					padding-left: calcFluidAll(12, 28);
					padding-right: calcFluidAll(12, 28);
				}

			}

		}
	}

	&--desktop_notify{
		display: none;

		&.active{
			display: inherit;
		}
	}

	&--mobile_notify {
		.notify--top{
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&--mobile_notify-overlay{
		display: none;
		justify-content: center;
		align-items: center;
		position: fixed;
		padding-left: 20px;
		padding-right: 20px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.479);
		z-index: 50;

		&.active{
			display: flex;
		}
	}

	&--select_modal{

		.modal--top{
			border-bottom: 1px solid #C9C9C9;
			padding-bottom: 18px;
			margin-bottom: 30px;

			.top--label{
				font-weight: 600;
				font-size: 20px;
				line-height: 125%;
				color: #272727;
			}

		}

		.modal--bottom{
			.bottom--list{
				display: flex;
				flex-wrap: wrap;

				.list--item{
					width: 180px;
					margin-right: 20px;
					height: fit-content;
					font-size: 18px;
					line-height: 125%;
					color: #272727;
					margin-bottom: 30px;

					.item--link{

						&:hover{
							color: #ec1c24;
						}

					}

				}

			}
		}

	}
}