.video{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        background-color: #F5F5F5;
        border-radius: 20px;
        padding: 90px 168px 100px 130px;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            padding: calcFluid(60, 90) calcFluid(112, 168) calcFluid(66.67, 100) calcFluid(86.67, 130);
        }
        @include _992{
            border-radius: 20px;
            padding: 50px 21px 52px;
        }
    }
    &__info{
        display: grid;
        grid-template-columns: 1fr 25%;
        margin-bottom: 57px;
        @include _1920{
            margin-bottom: calcFluid(38, 57);
        }
        @include _992{
            margin-bottom: 34px;
            grid-template-columns: 1fr;
            row-gap: 16px;
        }
        &__title{
            font-size: 48px;
            line-height: 55.2px;
            padding-left: 20px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
                padding-left: calcFluid(13.33, 20);
            }
            @include _992{
                font-size: 21px;
                line-height: 26.25px;
                padding-left: 0px;
            }
        }
        &__time{
            display: flex;
            margin-top: auto;
            margin-left: auto;
            margin-right: 5px;
            @include _1920{
                margin-right: calcFluid(3.33, 5);
            }
            @include _992{
                margin-left: 0px;
            }
            svg{
                fill: $main-clr;
                width: 50px;
                height: 50px;
                margin-right: 21px;
                @include _1920{
                    width: calcFluid(33.33, 50);
                    height: calcFluid(33.33, 50);
                    margin-right: calcFluid(14, 21);
                }
                @include _992{
                    width: 38px;
                    height: 38px;
                    margin-right: 9px;
                }
            }
            &__text{
                display: flex;
                flex-direction: column;
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    color: #636363;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 15px;
                    }
                    &:last-child{
                        font-size: 21px;
                        line-height: 26.25px;
                        color: #272727;
                        @include _1920{
                            font-size: calcFluid(14, 21);
                            line-height: calcFluid(17.50, 26.25);
                        }
                        @include _992{
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                }
            }
        }
    }
    &__content{
        border: 1px solid #A9A9A9;
        height: 735px;
        border-radius: 20px;
        position: relative;
        @include _1920{
            border: calcFluid(0.67, 1) solid #A9A9A9;
            height: calcFluid(490, 735);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            border-radius: 20px;
            height: calcFluidMobile(141, 350);
        }
        iframe{
            transform: translate(20px, -20px);
            height: 100%;
            width: 100%;
            border-radius: 20px;
            @include _1920{
                border-radius: calcFluid(13.33, 20);
                transform: translate(calcFluid(13.33, 20), calcFluid(-13.33, -20));
            }
            @include _992{
                border-radius: 20px;
                transform: translate(10px, -10px);
            }
        }
        span{
            display: block;
            position: absolute; 
            background-color: #F5F5F5;
        }
        &__back-1{
            width: 1px;
            left: -1px;
            height: 110px;
            top: 72px;
            @include _1920{
                width: calcFluid(0.67, 1);
                left: calcFluid(-0.67, -1);
                height: calcFluid(73.33, 110);
                top: calcFluid(48, 72);
            }
            @include _992{
                left: -1px;
                top: 15%;
                height: 15%;
                width: 1px;
            }
            
        }
        &__back-2{
            width: 1px;
            left: -1px;
            height: 188px;
            top: 290px;
            @include _1920{
                width: calcFluid(0.67, 1);
                left: calcFluid(-0.67, -1);
                height: calcFluid(125.33, 188);
                top: calcFluid(193.33, 290);
            }
            @include _992{
                left: -1px;
                top: 45%;
                height: 20%;
                width: 1px;
            }
        }
        &__back-3{
            width: 263px;
            height: 1px;
            bottom: -1px;
            left: 115px;
            @include _1920{
                width: calcFluid(175.33, 263);
                height: calcFluid(0.67, 1);
                bottom: calcFluid(-0.67, -1);
                left: calcFluid(76.67, 115);
            }
        }
        &__back-4{
            width: 327px;
            height: 1px;
            bottom: -1px;
            left: 818px;
            @include _1920{
                width: calcFluid(218, 327);
                height: calcFluid(0.67, 1);
                bottom: calcFluid(-0.67, -1);
                left: calcFluid(545.33, 818);
            }
        }
    }
}
.video-gallery{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 40.2% 1fr;
        column-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: 1fr;
            column-gap: 40px;
        }
    }
    &__left{
        display: flex;
        flex-direction: column;
        @include _992{
            margin-bottom: 20px;
        }
        &__video{
            width: 100%;
            height: 505px;
            margin-bottom: 20px;
            border-radius: 20px;
            overflow: hidden;
            @include _1920{
                height: calcFluid(336.67, 505);
                margin-bottom: calcFluid(13.33, 20);
                border-radius: calcFluid(13.33, 20);
            }
            @include _992{
                height: calcFluidMobile(141, 320);
                margin-bottom: 14px;
                border-radius: 20px;
            }
            iframe{
                height: 100%;
                width: 100%;
            }
        }
    }
    &__right{
        display: flex;
        flex-direction: column;
    }
    &__item{
        display: flex;
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 40px;
            flex-direction: column;
        }
        &__video{
            margin-right: 30px;
            height: 230px;
            min-width: 405px;
            border-radius: 20px;
            overflow: hidden;
            @include _1920{
                margin-right: calcFluid(20, 30);
                height: calcFluid(153.33, 230);
                min-width: calcFluid(270, 405);
                border-radius: calcFluid(13.33, 20);
            }
            @include _992{
                margin-right: 0px;
                height: calcFluidMobile(141, 320);
                min-width: none;
                width: 100%;
                border-radius: 20px;
                margin-bottom: 14px;
            }
            iframe{
                height: 100%;
                width: 100%;
            }
        }
        &__info{ 
            display: flex;
            flex-direction: column;
            padding: 12px 0;
            @include _1920{
                padding: calcFluid(8, 12) 0;
            }
            @include _992{
                padding: 0;
            }
        }
        &__title{
            margin-bottom: 14px;
            @include _1920{
                margin-bottom: calcFluid(9.33, 14);
            }
            @include _992{
                margin-bottom: 0;
            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__text{
            width: 95%;
            @include _992{
                display: none;
            }
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
            }
        }
    }
}