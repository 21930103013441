.product-card{
    $this: &;

    &[data-state="wishlist"] {
        #{$this}__actions-extra{
            flex-direction: row-reverse;
        }
    }

    &__inner{
        background: #fff;
        border: 1px solid #D0D0D0;
        padding: 20px;
        position: relative;
        height: 100%;
        transition: height $tr-time;
        @include _576{
            padding: 0;
        }
    }
    &__wrapper{
        position: relative;
        z-index: 2;
        background-color: #fff;
    }
    &__main{
        display: block;
        @include _576{
            padding: 15px;
            display: grid;
            grid-template-columns: 85px 1fr;
            grid-column-gap: 15px;
            align-items: flex-start;
        }
    }
    &__thumb{
        display: block;
        height: 200px;
        @include _1200{
            height: 140px;
        }
        @include _576{
            height: 65px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__labels{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        @include _576{
            top: 5px;
            left: 5px;
        }
        &-item{
            display: inline-block;
            padding: 5px 15px;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            border-radius: 140px;
            &--sale{
                background-color: #E55301;
            }
            &--new{
                background-color: #3FAFE3;
            }
            &--hit{
                background-color: #9B6BE8;
            }
            &:not(:last-child){
                margin: 0 0 5px;
                @include _576{
                    margin: 0 0 3px;
                }
            }
            @include _576{
                font-size: 12px;
                line-height: 18px;
                padding: 3px 8px;
            }
        }
    }
    &__content{
        display: block;
        padding: 30px 0;
        @include _576{
            padding: 0;
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &__price{
        display: block;
        padding: 0 0 5px;
        height: 40px;
        &--current{
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
            color: #282828;
            @include _576{
                font-size: 24px;
                line-height: 28px;
            }
        }
        &--old{
            display: inline-block;
            margin-left: 10px;
            font-size: 15px;
            line-height: 18px;
            color: #999999;
        }
        &--request{
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #282828;
            @include _576{
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
    &__name{
        font-size: 15px;
        line-height: 22px;
        color: #282828;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 45px;
        @include _576{
            margin: 0 0 20px;
        }
    }
    &__actions{
        justify-content: space-between;
        @include _576{
            padding: 15px 15px 0;
            border-top: 1px solid #E3E3E3;
        }
        &-cart{
            width: 100%;
            height: 45px;
        }
        &-extra{
            .btn{
                height: 45px;
                width: 45px;
                &[data-state='added']{
                    svg{
                        fill: $main-clr-hover;
                    }
                }
            }
        }
    }
    &__hidden{
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 20px;
        opacity: 0;
        transition: opacity $tr-time;
        .btn{
            width: 100%;
            height: 45px;
        }

        @include _576{
            position: static;
            opacity: 1;
            margin: 0;
            padding: 15px;
        }
    }

    &:hover{
        z-index: 9;
        #{$this}__inner{
            height: calc(100% + 55px);
            @include _576{
                height: 100%;
            }
        }
        #{$this}__hidden{
            opacity: 1;
        }
    }
}




.product--card{

    display: block;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    border-radius: 20px;
    padding: 35px 30px 45px 30px;

    @include _1920{
        padding-top: calcFluidAll(20, 35);
        padding-right: calcFluidAll(15, 30);
        padding-bottom: calcFluidAll(25, 45);
        padding-left: calcFluidAll(15, 30);
    }

    .card{

        &--wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        &--img{
            width: 100%;
            height: 340px;
            object-fit: contain;
            margin-bottom: 25px;

            @include _1920{
                height: calcFluidAll(260, 340);
            }

        }

        &--title{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 600;
            font-size: 27px;
            line-height: 125%;
            text-align: center;
            color: #272727;
            height: 70px;
            margin-bottom: 14px;

            @include _1920{
                font-size: calcFluidAll(16, 27);
                height: calcFluidAll(40, 70);
            }
        }

        &--prices{

            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 25px;

            .price{
                &--old{
                    font-size: 27px;
                    line-height: 125%;
                    text-align: center;
                    text-decoration-line: line-through;
                    color: #A9A9A9;

                    @include _1920{
                        font-size: calcFluidAll(16, 27);
                    }
                }

                &--now{
                    font-size: 48px;
                    line-height: 115%;
                    text-align: center;
                    color: #272727;

                    @include _1920{
                        font-size: calcFluidAll(32, 48);
                    }
                }

            }

        }

        &--link{
            width: 100%;
            max-width: 330px;

            font-size: 21px;
            line-height: 125%;

            &:hover{
                color: #FFFFFF;
                background-color: #fb474e;
                border-color: #fb474e;
            }

            @include _1920{
                font-size: calcFluidAll(16, 21);
            }

        }

    }

    &:hover{
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.18);
        .card{
            &--title{
                color: #EC1C24;
            }
        }
    }

}