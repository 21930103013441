.title-underline{
    border-bottom: 2px solid $main-clr;
    padding: 0 0 18px;
    @include _1920{
        border-bottom: calcFluid(1.33, 2) solid $main-clr;
        padding: 0 0 calcFluid(12, 18);
    }
}

.content-text{
    overflow: hidden;
    h1{
        font-weight: 400;
        font-size: 48px;
        line-height: 55.2px;
        color: $text-clr;
        margin: 0 0 30px;
        @include _1920{
            font-size: calcFluid(32, 48);
            line-height: calcFluid(36.80, 55.2);
            margin: 0 0 calcFluid(20, 30);
        }
        @include _992{
            font-size: 24px;
            line-height: 27.6px;
            margin: 0 0 18px;
        }
    }
    h2{
        font-weight: 400;
        font-size: 48px;
        line-height: 55.2px;
        color: $text-clr;
        margin: 0 0 30px;
        @include _1920{
            font-size: calcFluid(32, 48);
            line-height: calcFluid(36.80, 55.2);
            margin: 0 0 calcFluid(20, 30);
        }
        @include _992{
            font-size: 24px;
            line-height: 27.6px; 
            margin: 0 0 18px;
        }
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $text-clr;
        margin: 40px 0 5px;
        @include _1920{
            font-size: calcFluid(13.33, 20);
            line-height: calcFluid(17.33, 26);
            margin: calcFluid(26.67, 40) 0 calcFluid(3.33, 5);
        }
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $text-clr;
        margin: 35px 0 10px;
        @include _1920{
            font-size: calcFluid(13.33, 20);
            line-height: calcFluid(18.67, 28);
            margin: calcFluid(23.33, 35) 0 calcFluid(6.67, 10);
        }
    }
    p, a, li{
        font-size: 21px;
        line-height: 31.5px;
        color: $text-clr;
        @include _1920{
            font-size: calcFluid(14, 21);
            line-height: calcFluid(21, 31.5);
        }
        @include _992{
            font-size: 16px;
            line-height: 24px;
        }
    }
    p{
        margin: 0 0 8px;
        @include _1920{
            margin: 0 0 calcFluid(5.33, 8);
        }
    }
    a{
        color: $main-clr;
        transition: color $tr-time;
        border-bottom: 1px solid $main-clr;
        @include _1920{
            border-bottom: calcFluid(0.67, 1) solid $main-clr;
        }
        &:hover{
            color: $text-clr;
            border-bottom: 1px solid $text-clr;
            @include _1920{
                border-bottom: calcFluid(0.67, 1) solid $text-clr;
            }
        }
        &:visited{
            color: #AA85D7;
            border-bottom: 1px solid #AA85D7;
            @include _1920{
                border-bottom: calcFluid(0.67, 1) solid #AA85D7;
            }
        }
    }
    img{
        max-width: 100%;
        //width: 100%;
        margin: 35px 0;
        @include _1920{
            margin: calcFluid(23.33, 35) 0;
        }
    }

    ol, ul{
        padding-left: 22px;
        margin: 22px 0;
        @include _1920{
            padding-left: calcFluid(14.67, 22);
            margin: calcFluid(14.67, 22) 0;
        }
        @include _992{
            padding-left: 16px;
            margin: 15px 0;
        }
        li{
            padding-left: 12px;
            @include _1920{
                padding-left: calcFluid(8, 12);
            }
            @include _992{
                padding-left: 12px;
            }
            &:not(:last-child){
                // margin: 0 0 23px;
                // @include _1920{
                //     margin: 0 0 calcFluid(15.33, 23);
                // }
                // @include _992{
                //     margin: 0 0 15px;
                // }
            }
        }
    }

    ol{
        li{
            list-style-type: decimal;
        }
    }
    ul{
        li{
            list-style-type: none;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                background-color: $main-clr;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 4px);
                left: -10px;
                @include _1920{
                    width: calcFluid(3.33, 5);
                    height: calcFluid(3.33, 5);
                    left: calcFluid(-6.67, -10);
                }
                @include _992{
                    width: 8px;
                    height: 8px;
                    left: -10px;
                    border-radius: 2px;
                    top: 8px;
                }
            }
        }
    }
    .table-wrap{
        max-width: 100%;
        overflow: auto;
    }
    .table-caption{ 
        font-size: 48px; 
        line-height: 55.2px;
        color: $text-clr;
        text-align: left;
        margin-bottom: 55px;
        @include _1920{
            font-size: calcFluid(32, 48);
            line-height: calcFluid(36.80, 55.2);
            margin-bottom: calcFluid(36.67, 55);
        }
        @include _992{
            font-size: 24px;
            line-height: 27.6px;
            margin-bottom: 27.6px;
        }
    }
    table{
        position: relative;
        margin-bottom: 35px;
        border: none;
        width: 100%;
        border-collapse: collapse;
        @include _1920{
            margin-bottom: calcFluid(23.33, 35);
        }
        @include _992{
            display: block;
            overflow-x: auto;
        }
        &::after{
            display: block;
            position: absolute;
            top: 15%;
            right: 20px;
            z-index: 100;
            width: 36px;
            height: 41px;
            background-image: url('/static/images/scroll.png');
            background-repeat: no-repeat;
            background-size: cover;
            animation-duration: 1s;
            animation-name: tablescroll;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            @include _992{
                content: '';
            }
        }
        > caption{
            font-size: 48px; 
            line-height: 55.2px;
            color: $text-clr;
            text-align: left;
            margin-bottom: 55px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
                margin-bottom: calcFluid(36.67, 55);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
                margin-bottom: 27.6px;
            }
        }

        tbody{
            border: none;
            th,td{
                font-size: 21px;
                line-height: 26.25px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(17.50, 26.25);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        thead{
            background-color: #FFFBD4;
        }

        th, td{
            padding: 22px 42px;
            font-size: 24px;
            line-height: 30px;
            color: $text-clr;
            text-align: left;
            border: 1px solid #C9C9C9;
            @include _1920{
                padding: calcFluid(14.67, 22) calcFluid(28, 42);
                font-size: calcFluid(16, 24);
                line-height: calcFluid(20, 30);
                border: calcFluid(0.67, 1) solid #C9C9C9;
            }
            @include _992{
                padding: 16px 24px 20px;
                font-size: 18px;
                line-height: 22.5px;
            }
        }

        th{
            font-weight: 600;
        }
    }
}

@keyframes tablescroll {
    from{
        margin-right: -40px;
    }
    to{
        margin-right: 0px;
    }
}