.linking{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        row-gap: 30px;
        &.opened{
            grid-template-rows: 1fr;
        }
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(20, 30);
        }
        @include _992{
            grid-template-rows: repeat(6, 1fr) repeat(30, 0px);
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0;
            overflow: hidden;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        &:last-child:nth-child(2n+1){
            @include _992{
                width: 50%;
                margin: 0 auto;
                grid-column: 1/-1;
            }
            @include _576{
                width: 100%;
            }
        }
        @include _992{
            margin-bottom: 20px;
            margin-top: 1px;
        }
    }
}