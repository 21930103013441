.banner-form{
    margin-bottom: 100px;
    position: relative;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;    
    }
    &__bg{
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        &.center{
            justify-content: center;
        }
        @include _992{
            position: initial;
            height: calcFluidMobile(151, 302);
            width: 100%;
        }
    }
    &__content{
        display: flex;
        position: relative;
        z-index: 2;
        @include _992{
            flex-direction: column;
        }
    }
    &__left{
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,255,.8);
        margin-left: 75px; 
        width: 45.7%;
        padding: 70px 60px 100px;
        @include _1920{
            margin-left: calcFluid(50, 75);
            padding: calcFluid(46.67, 70) calcFluid(40, 60) calcFluid(66.67, 100);
        }
        @include _992{
            width: 100%;
            margin-left: 0px;
            padding: 20px 21px 0px;
        }
        &__title{
            font-size: 48px;
            line-height: 55.2px;
            margin-bottom: 31px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
                margin-bottom: calcFluid(20.67, 31);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
                margin-bottom: 24px;
            }
        }
        &__text{
            font-size: 21px;
            line-height: 31.5px;
            margin-bottom: 50px;
            @include _1920{
                font-size: calcFluid(14, 21);
                line-height: calcFluid(21, 31.5);
                margin-bottom: calcFluid(33.33, 50);
            }
            @include _992{
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 40px;
            }
        }
        &__button{
            width: 48%;
            @include _992{
                width: 100%;
            }
        }
        &.open-adv{
            padding: 57px 60px 59px; 
            @include _1920{
                padding: calcFluid(38, 57) calcFluid(40, 60) calcFluid(39.33, 59);
            }
            .banner-form__left{
                &__title{
                    font-size: 63px;
                    line-height: 69.3px;
                    @include _1920{
                        font-size: calcFluid(42, 63);
                        line-height: calcFluid(46.20, 69.3);
                    }
                    @include _992{
                        font-size: 30px;
                        line-height: 33px;
                    }
                }

                &__text{
                    margin-bottom: 30px;
                    @include _1920{
                        margin-bottom: calcFluid(20, 30);
                    }
                    @include _992{
                        margin-bottom: 24px;
                    }
                }
                &__button{

                }

            }
            .banner-from__advantages{
                margin-bottom: 49px;
                @include _1920{
                    margin-bottom: calcFluid(32.67, 49);
                }
                @include _992{
                    margin-bottom: 40px;
                }
                &__item{
                    display: flex;
                    align-items: center;
                    &:not(:last-child){
                        margin-bottom: 20px;
                        @include _1920{
                            margin-bottom: calcFluid(13.33, 20);
                        }
                        @include _992{
                            margin-bottom: 24px;
                        }
                    }
                    img{
                        margin-right: 20px;
                        width: 40px;
                        height: 40px;
                        @include _1920{
                            margin-right: calcFluid(13.33, 20);
                            width: calcFluid(26.67, 40);
                            height: calcFluid(26.67, 40);
                        }
                        @include _992{
                            margin-right: 12px;
                            width: 30px;
                            height: 30px;
                        }
                    }

                }
            }
        }
        &.center{
            display: none;
        }
    }
}