.advantages{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }

}
.advantages-img{
    display: grid;
    
    column-gap: 45px;
    row-gap: 45px;
    grid-template-columns: repeat(3, 1fr);
    @include _1920{
        column-gap: calcFluid(30, 45);
        row-gap: calcFluid(30, 45);
    }
    @include _992{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 1fr) repeat(20, 0);
        row-gap: 0px;
        overflow: hidden;
    }
    @include _576{
        grid-template-columns: 1fr;
    }
    &.opened{
        @include _992{
            grid-template-rows: 1fr;
            
        }
    }
    &-item{
        @include _992{
            margin-bottom: 20px;
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                grid-column: 1/-1;
                width: 50%;
                margin: 0 auto;
            }
            @include _576{
                width: 100%;
            }
        }
        &.default{
            border: 1px solid #C9C9C9;
            border-radius: 20px;
            padding: 35px 37px 50px 40px;
            @include _1920{
                border: calcFluid(0.67, 1) solid #C9C9C9;
                border-radius: calcFluid(13.33, 20);
                padding: calcFluid(23.33, 35) calcFluid(24.67, 37) calcFluid(33.33, 50) calcFluid(26.67, 40);
            }
            @include _992{
                border-radius: 20px;
                padding: 30px 24px;
            }
            .advantages-img-item{
                &__title{
                    margin-bottom: 20px;
                    @include _1920{
                        margin-bottom: calcFluid(13.33, 20);
                    }
                    @include _992{
                        margin-bottom: 12px;
                    }
                    span{
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 600;
                        @include _1920{
                            font-size: calcFluid(16, 24);
                            line-height: calcFluid(20, 30);
                        }
                        @include _992{
                            font-size: 18px;
                            line-height: 22.5px;
                        }
                    }
                }
                &__text{
                    span{
                        font-size: 21px;
                        line-height: 31.5px;
                        @include _1920{
                            font-size: calcFluid(14, 21);
                            line-height: calcFluid(21, 31.5);
                        }
                        @include _992{
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
    
        }
        &.image{
            img{
                border-radius: 20px;
                width: 100%;
                height: 100%;
                @include _1920{
                    border-radius: calcFluid(13.33, 20);
                }
                @include _992{
                    border-radius: 20px;
                }

            }
    
        }
        &.have-a-question{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid $main-clr;
            border-radius: 20px;
            @include _1920{
                border: calcFluid(0.67, 1) solid $main-clr;
                border-radius: calcFluid(13.33, 20);
            }
            @include _992{
                border-radius: 20px;
                padding: 20px 0;
                overflow: hidden;
            }
            .advantages-img-item{
                &__title{
                    margin-bottom: 29px;
                    @include _1920{
                        margin-bottom: calcFluid(19.33, 29);
                    }
                    @include _992{
                        margin-bottom: 12px;
                    }
                    span{
                        font-size: 48px;
                        line-height: 55.2px;
                        @include _1920{
                            font-size: calcFluid(32, 48);
                            line-height: calcFluid(36.80, 55.2);
                        }
                        @include _992{
                            font-size: 18px;
                            line-height: 22.5px;
                        }
                    }
                }
                &__button{
                    margin-bottom: 10px;
                    width: 52%;
                    @include _1920{
                        margin-bottom: calcFluid(6.67, 10);
                    }
                    @include _992{
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

}
.advantages-ico{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 45px;
    row-gap: 45px;
    @include _1920{
        column-gap: calcFluid(30, 45);
        row-gap: calcFluid(30, 45);
    }
    @include _992{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, auto);
        grid-auto-rows: 0px;
        row-gap: 20px;
        overflow: hidden;
    }
    @include _576{
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
    }
    &.opened{
        @include _992{
            grid-auto-rows: auto;
        }
    }
    &-item{
        border: 1px solid #c9c9c9;
        border-radius: 20px;
        padding: 40px 65px 53px 70px;
        display: grid;
        grid-template-columns: 84px 1fr;
        column-gap: 65px;
        @include _1920{
            border: calcFluid(0.67, 1) solid #c9c9c9;
            border-radius: calcFluid(13.33, 20);
            padding: calcFluid(26.67, 40) calcFluid(43.33, 65) calcFluid(35.33, 53) calcFluid(46.67, 70);
            grid-template-columns: calcFluid(56, 84) 1fr;
            column-gap: calcFluid(43.33, 65);
        }
        @include _992{
            margin-bottom: 20px;
            border-radius: 20px;
            padding: 30px 24px 35px;
            grid-template-columns: 1fr;
            grid-template-rows: 40px 1fr;
            row-gap: calcFluidMobile(10, 20);
            
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                grid-column: 1/-1;
                width: 50%;
                margin: 0 auto;
            }
            @include _576{
                width: 100%;
            }
        }
        &__title{
            margin-bottom: 16px;
            @include _1920{
                margin-bottom: calcFluid(10.67, 16);
            }
            @include _992{
                margin-bottom: 14px;
            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__text{
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__image{
            img{
                height: 84px;
                width: 84px;
                object-fit: contain;
                @include _1920{
                    height: calcFluid(56, 84);
                    width: calcFluid(56, 84);
                }
                @include _992{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
.advantages-val{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 45px;
    row-gap: 45px;
    @include _1920{
        column-gap: calcFluid(30, 45);
        row-gap: calcFluid(30, 45);
    }
    @include _992{
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
    }
    @include _576{
        grid-template-columns: 1fr;
    }
    &-item{
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 14px 0px #00000024;
        padding: 20px 40px 25px;
        border-radius: 20px;
        @include _1920{
            padding: calcFluid(13.33, 20) calcFluid(26.67, 40) calcFluid(16.67, 25);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            padding:  20px 36px 30px;
            border-radius: 20px;
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                width: 50%;
                margin: 0 auto;
                grid-column: 1/-1;
            }
            @include _576{
                width: 100%;
            }
        }
        &__top{
            border-bottom: 1px solid #C9C9C9;
            padding-bottom: 10px;
            margin-bottom: 9px;
            @include _1920{
                border-bottom: calcFluid(0.67, 1) solid #C9C9C9;
                padding-bottom: calcFluid(6.67, 10);
                margin-bottom: calcFluid(6, 9);
            }
            @include _992{
                padding-bottom: 8px;
                margin-bottom: 10px;
            }
            span{
                font-size: 63px;
                line-height: 72.45px;
                @include _1920{
                    font-size: calcFluid(42, 63);
                    line-height: calcFluid(48.30, 72.45);
                }
                @include _992{
                    font-size: 40px;
                    line-height: 46px;
                }
            }
        }
        &__bottom{
            span{
                font-size: 21px;
                line-height: 26.25px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(17.50, 26.25);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        &.color{
            box-shadow: none;
            background-color: $main-clr;
            .advantages-val-item__top{
                border-color: #E9E9E9!important;
            }
            span{
                color: #fff!important;
            }
        }
    }
}