.theme-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
        width: 36px;
        height: 28px;
        fill: #14A356;
        margin: 0 0 25px;
        @include _1920{
            width: calcFluid(24, 36);
            height: calcFluid(18.67, 28);
            margin: 0 0 calcFluid(16.67, 25);
        }
    }

    span {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        color: #333333;
        @include _1920{
            font-size: calcFluid(18.67, 28);
            line-height: calcFluid(24, 36);
        }
    }
}


// Basic Modal Styles
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
}

.modal__container {
    background-color: #fff;
    padding: 76px 82px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 644px;
    border-radius: 20px;
    position: relative;
    @include _1920{
        padding: calcFluid(50.67, 76) calcFluid(54.67, 82);
        max-width: calcFluid(429.33, 644);
    }
    @include _992{
        max-width: 100%;
        width: 100%;
        border-radius: 0px;
        padding: 35px 21px 30px;
    }
    &.resume{
        padding: 46px 82px;
        @include _1920{
            padding: calcFluid(30.67, 46) calcFluid(54.67, 82);
        }
        @include _992{
            border-radius: calcFluid(13.33, 20);
        }

    }
    &.reviews{
        max-width: 704px;
        padding: 63px 60px;
        @include _1920{
            max-width: calcFluid(469.33, 704);
            padding: calcFluid(42, 63) calcFluid(40, 60);
        }
        @include _992{
            max-width: 100%;
            width: 100%;
            padding: 21px 21px 30px;
        }
        .modal{
            &__close{
                top: 15px;
                right: 22px;
                cursor: pointer;
                transition: color .3s ease;
                @include _1920{
                    top: calcFluid(10, 15);
                    right: calcFluid(14.67, 22);
                }
                @include _992{
                    top: 10px;
                    right: 12px ;
                }
                &:hover{
                    color: $main-clr-hover;
                }
            }
            &__header{
                align-items: start;
            }
            &__title{
                margin-bottom: 5px;
                @include _1920{
                    margin-bottom: calcFluid(3.33, 5);
                }
                @include _992{
                    margin-bottom: 10px;
                }
            }
            &__subtitle{
                text-align: start;
            }
        }
        .custom-form{
            &__load{
                align-items: start;
                margin-bottom: 0px;
            }
            &__load-count{
                margin-bottom: 30px;
                @include _1920{
                    margin-bottom: calcFluid(20, 30);
                }
                @include _992{
                    margin-bottom: 20px;
                }
            }
        }

    }

    &.select_city{
        padding: 20px 20px 40px 25px;
        margin: 10px;

        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.18);
        border-radius: 20px;
        max-width: 950px;

        .modal__header{
            display: none;
        }

        .modal__close{
            top: 15px;
        }
    }

    &.order_callback{
        background: #FFFFFF;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.18);
        border-radius: 20px;

        margin-left: 20px;
        margin-right: 20px;
    }

}
.modal{
    &__close{
        border: none;
        background-color: transparent;
        position: absolute;
        font-size: 34px;
        line-height: 1;
        top: 15px;
        right: 23px;
        top: 25px;
        right: 25px;
        transition: color .3s ease;
        cursor: pointer;
        @include _1920{
            font-size: calcFluid(22.67, 34);
            top: calcFluid(16.67, 25);
            right: calcFluid(16.67, 25);
        }
        @include _992{
            font-size: 23px;
            top: 10px;
            right: 10px;

        }
        &:hover{
            color: $main-clr-hover;
        }
    }
    &__header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title{
        text-align: center;
        font-weight: 600;
        font-size: 27px;
        line-height: 33.75px;
        margin-bottom: 14px;
        @include _1920{
            font-size: calcFluid(18, 27);
            line-height: calcFluid(22.50, 33.75);
            margin-bottom: calcFluid(9.33, 14);
        }
        @include _992{
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 10px;
        }
    }
    &__subtitle{
        text-align: center;
        font-size: 21px;
        line-height: 31.5px;
        margin-bottom: 30px;
        @include _1920{
            font-size: calcFluid(14, 21);
            line-height: calcFluid(21, 31.5);
            margin-bottom: calcFluid(20, 30);
        }
        @include _992{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }
    &__form{

        button{
            width: 100%;
            margin-bottom: 12px;
            @include _1920{
                margin-bottom: calcFluid(8, 12);
            }
            @include _992{
                margin-bottom: 10px;
            }
        }
    }
    &__personal{
        text-align: center;
        display: flex;
        flex-direction: column;
        span{
            font-size: 18px;
            line-height: 22.5px;
            color: #636363;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
            @include _992{
                font-size: 12px;
                line-height: 15px;
            }
        }
        a{
            font-size: 18px;
            line-height: 22.5px;
            text-decoration: underline;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
            @include _992{
                font-size: 12px;
                line-height: 15px;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    textarea{
        width: 100%;
        height: 230px;
        font-size: 21px;
        line-height: 26.25px;
        border-radius: 40px;
        padding: 20px 32px;
        margin-bottom: 15px;
        border: 1px solid #A9A9A9;
        resize: none;
        color: #333;
        @include _1920{
            height: calcFluid(153.33, 230);
            font-size: calcFluid(14, 21);
            line-height: calcFluid(17.50, 26.25);
            border-radius: calcFluid(26.67, 40);
            padding: calcFluid(13.33, 20) calcFluid(21.33, 32);
            margin-bottom: calcFluid(10, 15);
        }
        @include _992{
            height: 180px;
            font-size: 16px;
            line-height: 20px;
            border-radius: 30px;
            padding: 18px 24px;
            margin-bottom: 20px;
        }
        &.error{
            border-color: #ff0000;
        }
        &::placeholder{
            color: #636363;
        }
    }
}


@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        // transform: translateY(15%);
    }

    to {
        // transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        // transform: translateY(0);
    }

    to {
        // transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}