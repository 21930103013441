

.banner{
    margin-bottom: 100px;
    position: relative;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__bg{
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        height: 630px;
        position: relative;
        @include _1920{
            height: calcFluid(420, 630);
        }
        @include _992{
            height: calcFluidMobile(151, 300);
        }
    }
    &__content{
        display: flex;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        @include _992{
            position: initial;
            flex-direction: column;
            align-items: center;
            padding: 0 21px;
        }
    }
    &__left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        width: 45.7%;
        margin-left: 75px;
        padding: 0 90px 0 59px;
        @include _1920{
            margin-left: calcFluid(50, 75);
            padding: 0 calcFluid(60, 90) 0 calcFluid(39.33, 59);
        }
        @include _992{
            width: 100%;
            margin-left: 0px;
            padding: 0;
            margin-top: 40px;
        }
    }
    &__title{
        font-size: 63px;
        line-height: 69.3px;
        margin-bottom: 33px;
        @include _1920{
            font-size: calcFluid(42, 63);
            line-height: calcFluid(46.20, 69.3);
            margin-bottom: calcFluid(22, 33);
        }
        @include _992{
            font-size: 30px;
            line-height: 33px;
            margin-bottom: 24px;
        }
    }
    &__text{
        margin-bottom: 52px;
        @include _1920{
            margin-bottom: calcFluid(34.67, 52);
        }
        @include _992{
            margin-bottom: 40px;
        }
        li{
            &::before{
                content: '';
                position: relative;
                display: inline-block;
                border-radius: 3px;
                width: 12px;
                height: 12px;
                background-color: $main-clr;
                margin-right: 18px;
                @include _1920{
                    border-radius: calcFluid(2, 3);
                    width: calcFluid(8, 12);
                    height: calcFluid(8, 12);
                    margin-right: calcFluid(12, 18);
                }
                @include _992{
                    border-radius: 2px;
                    width: 8px;
                    height: 8px;
                    margin-right: 10px;
                }
            }
            &:not(:last-child){
                margin-bottom: 25px;
                @include _1920{
                    margin-bottom: calcFluid(16.67, 25);
                }
                @include _992{
                    margin-bottom: 25px;
                }
            }
        }
    }
    &__button{
        width: 356px;
        @include _1920{
            width: calcFluid(237.33, 356);
        }
        @include _992{
            width: 100%;
            height: 42px;
        }
        svg{
            fill: #fff;
            width: 32px;
            height: 32px;
            margin-right: 5px;
            @include _1920{
                width: calcFluid(21.33, 32);
                height: calcFluid(21.33, 32);
                margin-right: calcFluid(3.33, 5);
            }
            @include _992{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
    &__image{
        position: absolute;
        bottom: -92px;
        right: 74px;
        height: min-content;
        @include _1920{
            bottom: calcFluid(-61.33, -92);
            right: calcFluid(49.33, 74);
        }
        @include _992{
            top: calcFluidMobile(56, 112);
            right: 21px;
        }
        img{
            height: 566px;
            width: 865px;
            @include _1920{
                height: calcFluid(377.33, 566);
                width: calcFluid(576.67, 865);
            }
            @include _992{
                width: calcFluidMobile(176, 352);
                height: calcFluidMobile(112, 224);
            }
        }
    }
    &__timer{
        position: absolute;
        width: 190px;
        height: 406px;
        top: 112px;
        right: 44.65%;
        padding: 32px 17px 41px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 2px 16px 0px #0000001A;
        @include _1920{
            width: calcFluid(126.67, 190);
            height: calcFluid(270.67, 406);
            top: calcFluid(74.67, 112);
            padding: calcFluid(21.33, 32) calcFluid(11.33, 17) calcFluid(27.33, 41);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            position: initial;
            width: 100%;
            height: 110px;
            padding: 10px 24px 16px;
            border-radius: 20px;
            margin-top: 36px;

        }
        &__wrapper{
            display: flex;
            width: 100%;
            height: 100%;
            @include _992{
                flex-direction: column;
            }
        }
        &__title{
            display: flex;
            flex-direction: column;
            text-align: center;
            writing-mode: tb;
            transform: scale(-1);
            @include _992{
                writing-mode: initial;
                transform: initial;
            }
            span{
                margin-left: 17px;
                font-size: 18px;
                line-height: 22.5px;
                color: #636363;
                @include _1920{
                    margin-left: calcFluid(11.33, 17);
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
                @include _992{
                    font-size: 14px;
                    line-height: 17.5px;
                }
            }
            &::after{
                content: '';
                display: block;
                width: 1px;
                height: 272px;
                margin: auto;
                background-color: #A9A9A9;
                @include _1920{
                    width: calcFluid(0.67, 1);
                    height: calcFluid(181.33, 272);
                }
                @include _992{
                    display: none;
                }
            }
        }
        &__time{
            margin-top: 8px;
            margin-left: 32px;
            @include _1920{
                margin-top: calcFluid(5.33, 8);
                margin-left: calcFluid(21.33, 32);
            }
            @include _992{
                margin-top: calcFluidMobile(5, 10);
                margin-left: 0px;
                padding: 0 calcFluidMobile(10,100);
                display: flex;
                justify-content: space-between;
            }
        }
        &__time-item{
            text-align: center;
            margin-bottom: 10px;
            @include _1920{
                margin-bottom: calcFluid(6.67, 10);
            }
            &__number{
                margin-bottom: -9px;
                @include _1920{
                    margin-bottom: calcFluid(-6, -9);
                }
                span{
                    font-size: 44px;
                    line-height: 55px;
                    @include _1920{
                        font-size: calcFluid(29.33, 44);
                        line-height: calcFluid(36.67, 55);
                    }
                    @include _992{
                        font-size: 30px;
                        line-height: 37.5px;
                    }
                }
            }
            &__text{
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    } 
                    @include _992{
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }

        }
    }
    &__calc-form{
        width: 33.8%;
        background-color: #f5f5F5E5;
        margin: 45px 130px;
        border-radius: 20px;
        padding: 46px 82px;
        @include _1920{
            margin: calcFluid(30, 45) calcFluid(86.67, 130);
            padding: calcFluid(30.67, 46) calcFluid(54.33, 82);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            padding: 0px;
            margin: 36px 21px 60px 21px;
            background-color: #fff;
        }
    }
    &__size-from{
        width: 33.8%;
        background-color: #f5f5F5E5;
        margin: 45px 130px;
        border-radius: 20px;
        @include _1920{
            margin: calcFluid(30, 45) calcFluid(86.67, 130);
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            margin: 0;
            width: 100%;
            background-color: #fff;
        }
        &.center{
            width: 54.4%;
            margin: 59px auto;
            padding: 51px 80px 69px;
            @include _1920{
                margin: calcFluid(39.33, 59) auto;
                padding: calcFluid(34, 51) calcFluid(53.33, 80) calcFluid(46, 69);
            }
            @include _992{
                margin: 0px;
                padding: 40px 21px;
                width: 100%;
            }
        }
    }
}
.calc-form{
    display: flex;
    flex-direction: column;
    @include _1920{
        padding: calcFluid(30.67, 46) calcFluid(54, 81);
    }
    @include _992{
        width: 100%;
        padding: 0;
    }
    &__title{
        text-align: center;
        margin-bottom: 24px;
        @include _1920{
            margin-bottom: calcFluid(16, 24);
        }
        @include _992{
            width: 70%;
            margin: 0 auto 20px;
        }
        span{
            font-size: 27px;
            line-height: 33.75px;
            font-weight: 600;
            @include _1920{
                font-size: calcFluid(18, 27);
                line-height: calcFluid(22.50, 33.75);
            }
            @include _992{
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
}
.dealer-form{
    display: flex;
    flex-direction: column;
    margin: 20px 130px;
    padding: 20px 82px;
    @include _1920{
        padding: calcFluid(30.67, 46) calcFluid(54, 81);
        margin: calcFluid(14, 20) calcFluid(86.67, 130);
        padding: calcFluid(14, 20) calcFluid(54.33, 82);
    }
    @include _992{
        width: 100%;
        padding: 0;
        margin: 36px 21px 60px 21px;
    }
    @include _1920{
        border-radius: calcFluid(13.33, 20);
    }
}
.size-form{
    display: flex;
    flex-direction: column;
    padding: 76px 81px;
    @include _1920{
        padding: calcFluid(50.67, 76) calcFluid(54, 81);
    }
    @include _992{
        margin-top: 60px;
    }
    &__title{
        text-align: center;
        margin-bottom: 15px;
        @include _1920{
            margin-bottom: calcFluid(10, 15);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 27px;
            line-height: 33.75px;
            font-weight: 600;
            @include _1920{
                font-size: calcFluid(18, 27);
                line-height: calcFluid(22.50, 33.75);
            }
            @include _992{
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    &__description{
        text-align: center;
        margin-bottom: 40px;
        @include _1920{
            margin-bottom: calcFluid(26.67, 40);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 21px;
            line-height: 31.5px;
            @include _1920{
                font-size: calcFluid(14, 21);
                line-height: calcFluid(21, 31.5);
            }
            @include _992{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    &.center{
        .size-form{
            &__title{
                margin-bottom: 25px;
                @include _1920{
                    margin-bottom: calcFluid(16.67, 25);
                }
                @include _992{
                    margin-bottom: 10px;
                }
                span{
                    font-size: 48px;
                    line-height: 55.2px;
                    @include _1920{
                        font-size: calcFluid(32, 48);
                        line-height: calcFluid(36.80, 55.2);
                    }
                    @include _992{
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
            }
        }
        .custom-form__input-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 84%;
            margin: auto;
            column-gap: 14px;
            @include _1920{
                column-gap: calcFluid(9.33, 14);
            }
            @include _992{
                grid-template-columns: 1fr;
            }
        }
    }
}