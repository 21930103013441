@import './content/text';
@import './content/seo';
@import './content/product-tab';
@import './content/product';
@import './content/banner';
@import './content/calculator'; 
@import './content/banner-form'; 
@import './content/advantages'; 
@import './content/heading';  
@import './content/linking';  
@import './content/process'; 
@import './content/portfolio'; 
@import './content/video'; 
@import './content/tariff'; 
@import './content/sertificate'; 
@import './content/comment'; 
@import './content/documents'; 
@import './content/big-link'; 


.content-block{
    margin: 35px auto;
    @include _992{
        margin: 30px auto;
    }
}
