.product-block{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr repeat(30, 0px);
            row-gap: 0px;
            overflow: hidden;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
        &.opened{
            grid-template-rows: 1fr;
            row-gap: 30px;
        }
    }
    &.background{
        background-color: #F5F5F5;
        padding: 100px 0;
        @include _1920{
            padding: calcFluid(66.67, 100) 0;
        }
    }
}
.product-block-card{
    border: 1px solid #C9C9C9;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 33px 28px 43px;
    min-height: 711px;
    @include _1920{
        border: calcFluid(0.67, 1) solid #C9C9C9;
        border-radius: calcFluid(13.33, 20);
        padding: calcFluid(22, 33) calcFluid(18.67, 28) calcFluid(28.67, 43);
        min-height: calcFluid(474, 711);
    }
    @include _992{
        border-radius: 20px;
        padding: 22px 19px;
    }
    &__image{
        max-width: 100%;
        width: 100%;
        height: 338px;
        margin-bottom: 24px;
        @include _1920{
            height: calcFluid(225.33, 338);
            margin-bottom: calcFluid(16, 24);
        }
        @include _992{
            width: auto;
            height: 162px;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain; 
        }
    }
    &__title{
        text-align: center;
        span{
            font-weight: 600;
            font-size: 27px;
            line-height: 33.75px;
            @include _1920{
                font-size: calcFluid(18, 27);
                line-height: calcFluid(22.50, 33.75);
            }
            @include _992{
                font-size: 20px;
                line-height: 25px;
            }
        }
        &.desc-on{
            min-height: 86px;
            @include _1920{
                min-height: calcFluid(57.33, 86);
            }
            @include _992{
                min-height: auto;
            }
        }
    }
    &__price-old{
        margin-top: auto;
        margin-bottom: 20px;
        span{
            font-size: 27px;
            line-height: 33.75px;
            text-decoration-line: line-through;
            color: #a9a9a9;
            @include _1920{
                font-size: calcFluid(18, 27);
                line-height: calcFluid(22.50, 33.75);
            }
            @include _992{
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    &__price{
        margin-bottom: 24px;
        text-align: center; 
        @include _1920{
            margin-bottom: calcFluid(16, 24);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 30px;
                line-height: 34.5px;
            }
        }
        &.desc-on{
            margin-top: auto; 
        }
    }
    &__button{
        width: 329px;
        margin-top: auto;
        @include _1920{
            width: calcFluid(219.33, 329);
        }
        @include _992{
            width: 100%;
        }
    }
    &__description{
        min-height: 130px;
        width: 90%;
        text-align: center;
        margin-bottom: 20px;
        @include _1920{
            min-height: calcFluid(86.67, 130);
        }
        @include _992{
            min-height: auto;
        }
        span{
            font-size: 21px;
            line-height: 31.5px;
            @include _1920{
                font-size: calcFluid(14, 21);
                line-height: calcFluid(21, 31.5);
            }
            @include _992{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

}