body{
	min-height: 100vh;
	margin: 0;
	color: $text-clr;
	font-family: 'Open Sans', sans-serif;
	display: flex;
	flex-direction: column;
	position: relative;
	font-size: 21px;
	line-height: 26.25px;
	@include _1920{
    	font-size: calcFluid(14, 21);
    	line-height: calcFluid(17.50, 26.25);
	}
	@include _992{
		font-size: 16px;
		line-height: 24px;
	}
	&.opened{
		overflow: hidden;
	}
}
main{
	position: relative;
	flex: 1 0 auto;
}
p,ol,ul,h1,h2,h3,button,form{
	margin: 0;
	padding: 0;
}
button, input, textarea{
	font-family: inherit;
	&:focus{
		outline: none;
	}
}
input{
	min-width: 0;
	font-size: inherit;
	appearance: none;
	@include _992{
		font-size: 16px;
		line-height: 20px;
	}
}
ul, li{
	list-style-type: none;
	// margin-left: 30px;

}
ul{
	padding-top: 10px;
	padding-bottom: 10px;
}

li{
	padding-top: 5px;
	padding-bottom: 5px;
}

div.career__item__body__text  p{
	padding-bottom: 10px;
}

input::-webkit-input-placeholder{
	font-size: 21px;
	line-height: 26.25px;
	color: #999999;
	@include _1920{
    	font-size: calcFluid(14, 21);
    	line-height: calcFluid(17.50, 26.25);
	}
	@include _992{
		font-size: 16px;
		line-height: 20px;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
a{
	color: $text-clr;
	text-decoration: none;
	transition: color $tr-time;
	&:hover{
		color: var(--main);
	}
}
svg{
	vertical-align: middle;
	transition: fill $tr-time;
}
picture{
	display: block;
}
img{
	display: block;
}
source{
	display: none;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}