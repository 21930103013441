
.footer{
    background-color: $alt-clr;
    &-main{
        width: 100%;
        padding: 41px 0 50px;
        display: grid;
        grid-template-columns: 25.6% 12.2% 17.2% 13.6% 12% 1fr;
        @include _1920{
            padding: calcFluid(27.33, 41) 0 calcFluid(33.33, 50);
        }
        @include _992{
            display: flex;
            flex-wrap: wrap;
            padding: 40px 0;
        }
        &__catalog{
            display: flex;
            flex-direction: column;
            width: 67%;
            @include _992{
                width: 50%;
                margin-bottom: 40px;
            }
            a{
                &:not(:last-child){
                    margin-bottom: 17px;
                    @include _1920{
                        margin-bottom: calcFluid(11.33, 17);
                    }
                    @include _992{
                        margin-bottom: 20px;
                    }
                }
                &:hover{
                    span{
                        color: $main-clr-hover;
                    }
                }
                span{
                    font-size: 21px;
                    line-height: 26.25px;
                    color: #fff;
                    transition: all .3s ease;
                    @include _1920{
                        font-size: calcFluid(14, 21);
                        line-height: calcFluid(17.50, 26.25);
                    }
                    @include _992{
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
        &__menu{
            display: flex;
            flex-direction: column;
            @include _992{
                width: 50%;
                margin-bottom: 40px;
            }

            a{  
                &:not(:last-child){
                    margin-bottom: 16px;
                    @include _1920{
                        margin-bottom: calcFluid(10.67, 16);
                    }
                    @include _992{
                        margin-bottom: 18px;
                    }

                }
                &:hover{
                    span{
                        color: $main-clr-hover;
                    }
                }
                
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    color: #C7C7C7;
                    transition: all .3s ease;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                    @include _992{
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
        &__logo{
            @include _992{
                margin-bottom: 40px;
                width: 100%;
            }
            &__img{
                svg{
                    height: 58px;
                    width: 254px;
                    @include _1920{
                        height: calcFluid(38.67, 58);
                        width: calcFluid(169.33, 254);
                    }
                    @include _992{
                        width: auto;
                        max-width: 100%;
                        height: 62px;
                    }
                }
            }
            &__search{
                margin-top: 30px;
                width: 355px;
                @include _1920{
                    margin-top: calcFluid(20, 30);
                    width: calcFluid(236.67, 355);
                }
                @include _992{
                    width: 100%;
                    height: 45px;
                    margin-top: 20px;
                }
                .search-line{
                    background-color: #343434;
                    @include _992{
                        max-width: 100%!important;
                        height: 45px;
                    }
                    button{
                        background-color: #343434;
                        svg{
                            stroke:#C9C9C9;
                        }

                    }
                    input{
                        color: #fff;
                    }
                }
            }
        }
        &__info{
            display: flex;
            flex-direction: column;
            margin-left: 85px;
            @include _1920{
                margin-left: calcFluid(56.67, 85);
            }
            @include _992{
                margin-left: 0px;
                width: 100%;
            }
            &__number{
                margin-bottom: 4px;
                @include _1920{
                    margin-bottom: calcFluid(2.67, 4);
                }
                @include _992{
                    margin-bottom: 4px;
                }
                &:hover{
                    span{
                        color: $main-clr-hover;
                    }
                }
                span{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #fff;
                    transition: color .3s ease;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                    @include _992{
                        font-size: 24px;
                        line-height: 30px;
                    }
                }
            }
            &__work{
                margin-bottom: 20px;
                @include _1920{
                    margin-bottom: calcFluid(13.33, 20);
                }
                @include _992{
                    margin-bottom: 22px;
                }
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    color: #C9C9C9;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                    @include _992{
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            &__btn{
                width: 254px;
                height: 60px;
                @include _1920{
                    width: calcFluid(169.33, 254);
                    height: calcFluid(40, 60);
                }
                @include _992{
                    width: 100%;
                    height: 52px;
                }
            }
            &__social{
                display: flex;
                margin-top: 30px;
                @include _1920{
                    margin-top: calcFluid(20, 30);
                }
                @include _992{
                    margin-top: 30px;
                }
                a{
                    &:hover{
                        svg{
                            fill: #fff;
                        }
                    }
                    &:not(:last-child){
                        margin-right: 20px;
                        @include _1920{
                            margin-right: calcFluid(13.33, 20);
                        }
                        @include _992{
                            margin-right: 30px;
                        }
                    }
                    svg{
                        width: 30px;
                        height: 30px;
                        fill: #969696;
                        @include _1920{
                            width: calcFluid(20, 30);
                            height: calcFluid(20, 30);
                        }
                        @include _992{ 
                            width: 36px;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
    &-bottom{
        padding: 20px 0;
        @include _1920{
            padding: calcFluid(13.33, 20) 0;
        }
        &__container{
            display: flex;
            justify-content: space-between;
            @include _576{
                flex-direction: column;
                margin-bottom: 22px;
            }
            span{
                color: #A9A9A9;
                font-size: 18px;
                line-height: 22.5px;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
                @include _992{
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 14px;
                }
            }
            .privacy_policy{
                font-size: 18px;
                line-height: 22.5px;
                color: #A9A9A9;
                border-bottom: 1px solid #A9A9A9;
                &:hover{
                    border-color: transparent;
                }
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                    border-bottom: calcFluid(0.67, 1) solid #A9A9A9;
                }
                @include _992{
                    font-size: 12px;
                    line-height: 15px;
                    border-bottom: none;
                    text-decoration: underline;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

.footer-ps{
    margin-right: 25px;
    @include _1920{
        margin-right: calcFluid(16.67, 25);
    }
    @include _992{
        display: flex;
        align-items: center;
    }
    &:hover{
        span{
            color: $main-clr;
        }
        svg{
            fill: $main-clr;
        }
    }
    span{
        font-size: 18px;
        line-height: 22.5px;
        color: #A9A9A9;
        transition: color .3s ease;
        @include _1920{
            font-size: calcFluid(12, 18);
            line-height: calcFluid(15, 22.5);
        }
        @include _992{
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 0px;
            margin-right: 4px;
        }
    }
    svg{
        width: 138px;
        height: 28px;
        fill: #A9A9A9;
        transition: fill .3s ease;
        @include _1920{
            width: calcFluid(92, 138);
            height: calcFluid(18.67, 28);
        }
        @include _992{
            width: 82px;
            height: 17px;
        }
    }
}