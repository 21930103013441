.comment{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        } 
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 20px;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        background-color: #F5F5F5;
        border-radius: 20px;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            border-radius: 20px;
            position: relative;
            overflow: hidden;
        }
        &__company{
            padding: 20px 40px 22px 40px;
            border-bottom: 1px solid #A9A9A9;
            @include _1920{
                padding: calcFluid(13.33, 20) calcFluid(26.67, 40) calcFluid(14.67, 22) calcFluid(26.67, 40);
                border-bottom: calcFluid(0.67, 1) solid #A9A9A9;
            }
            @include _992{
                padding: 20px 24px;
            }
            span{
                font-size: 27px;
                line-height: 33.75px;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
                &.company{
                    color: #272727;
                }
                &.no-company{
                    color: #8E8E8E;
                }
            }
        }
        &__name{
            padding: 32px 40px 0 40px;
            margin-bottom: 21px;
            @include _1920{
                padding: calcFluid(21.33, 32) calcFluid(26.67, 40) 0 calcFluid(26.67, 40);
                margin-bottom: calcFluid(14, 21);
            }
            @include _992{
                padding: 13px 24px 0;

            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__text{
            padding: 0 40px; 
            margin-bottom: 34px;
            @include _1920{
                padding: 0 calcFluid(26.67, 40);
                margin-bottom: calcFluid(22.67, 34);
            }
            @include _992{
                padding: 0 24px;
                margin-bottom: 16px;
            }
            span{
                font-size: 21px;
                line-height: 32px;

                -webkit-line-clamp: 7;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21.33, 32);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                    -webkit-line-clamp: 10;
                }
            }
            &.open{
                span{
                    -webkit-line-clamp: initial;
                }
            }
        }
        &__all{
            padding:  0 40px;
            margin-bottom: 30px;
            cursor: pointer;
            @include _1920{
                padding: 0 calcFluid(26.67, 40);
                margin-bottom: calcFluid(20, 30);
            }
            @include _992{
                padding: 0 24px;
                margin-bottom: 30px;
            }
            span{
                font-size: 21px;
                line-height: 26.25px;
                border-bottom: 1px dashed #272727;
                transition: color .3s ease;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(17.50, 26.25);
                    border-bottom: calcFluid(0.67, 1) dashed #272727;
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            &:hover{
                span{
                    color: $main-clr;
                    border-color: transparent;
                }
            }
        }
        &__links{
            padding: 0 40px;
            margin-bottom: 50px;
            @include _1920{
                padding: 0 calcFluid(26.67, 40);
                margin-bottom: calcFluid(33.33, 50);
            }
            @include _992{
                padding: 0 24px;
                margin-bottom: 35px;
            }
            &__scan{
                margin-right: 50px;
                @include _1920{
                    margin-right: calcFluid(33.33, 50);
                }
                @include _992{
                    margin-right: 50px;
                }
            }
            a{
                transition: all .3s ease;
                &:hover{
                    color: $main-clr;
                }
            }
        }
    }
    &__gallery{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 10px;
        padding:  0 40px;
        margin-bottom: 30px;
        @include _1920{
            column-gap: calcFluid(6.67, 10);
            padding: 0 calcFluid(26.67, 40);
            margin-bottom: calcFluid(20, 30);
        }
        @include _992{
            overflow-x: auto;
            padding: 0;
            margin: 0 24px;
            margin-bottom: 20px;
            width: 100%;
            column-gap: 10px;
        }
        &__item{
            position: relative;
            &:hover{
                p{
                    opacity: 1;
                    @include _992{
                        opacity: 0;
                    }
                }
                img{
                    transform: scale(0.891);
                    @include _992{
                        transform: scale(1);
                    }
                }
            }
            p{
                position: absolute;
                display: flex;
                height: 55px;
                opacity: 0;
                width: 55px;
                background-color: rgba(236, 28, 36, .8);
                border-radius: 50%;
                align-items: center; 
                justify-content: center;
                top: calc(50% - 28px);
                left: calc(50% - 28px);
                transition: opacity .3s ease;
                cursor: pointer;
                @include _1920{
                    height: calcFluid(36.67, 55);
                    width: calcFluid(36.67, 55);
                }
                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    width: 39px;
                    height: 39px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    font-size: 32px;
                    font-weight: 100;
                    @include _1920{
                        width: calcFluid(26, 39);
                        height: calcFluid(26, 39);
                        border: calcFluid(0.67, 1) solid #fff;
                        font-size: calcFluid(21.33, 32);
                    }
                }
            }

        }
        &__image{
            width: 150px;
            height: 98px;
            cursor: pointer;
            @include _1920{
                width: calcFluid(100, 150);
                height: calcFluid(65.33, 98);
            }
            @include _992{
                width: 150px;
                height: 98px;
            }
            img{
                transition: all .3s ease;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
                @include _1920{
                    border-radius: calcFluid(3.33, 5);
                }
            }
        }
    }
}