.sertificate{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__wrapper{
        display: grid;
        @include _992{
            row-gap: 30px;
        }
        &.inline-1{
            grid-template-columns: 38% 1fr;
            column-gap: 84px;
            @include _1920{
                column-gap: calcFluid(56, 84);
            }
            @include _992{
                grid-template-columns: 1fr;
            }
            .sertificate__content{
                grid-template-columns: 1fr;
                row-gap: 20px;
                @include _1920{
                    row-gap: calcFluid(13.33, 20);
                }
                @include _992{
                    row-gap: 10px;
                }
            }
            .sertificate__item{
                width: 705px;
                height: 555px;
                @include _1920{
                    width: calcFluid(470, 705);
                    height: calcFluid(370, 555);
                }
                @include _992{
                    height: calcFluidMobile(213, 350);
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
        &.inline-2{
            grid-template-columns: 33% 1fr;
            column-gap: 20px;
            @include _1920{
                column-gap: calcFluid(13.33, 20);
            }
            @include _992{
                grid-template-columns: 1fr;
                column-gap: 30px;
            }
            .sertificate__content{
                grid-template-columns: 1fr 1fr;
                column-gap: 20px;
                @include _1920{
                    column-gap: calcFluid(13.33, 20);
                }
                @include _992{
                    column-gap: 10px;
                    row-gap: 10px;
                }
            }
            .sertificate__item{
                width: 555px;
                height: 555px;
                @include _1920{
                    width: calcFluid(370, 555);
                    height: calcFluid(370, 555);
                }
                @include _992{
                    height: calcFluidMobile(178, 270);
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
        &.inline-3{
            grid-template-columns: 33% 1fr;
            column-gap: 20px; 
            @include _1920{
                column-gap: calcFluid(13.33, 20);
            }
            @include _992{
                grid-template-columns: 1fr;
                column-gap: 30px;
            }
            .sertificate__content{
                grid-template-columns: repeat(3, 1fr);
                column-gap: 20px;
                @include _1920{
                    column-gap: calcFluid(13.33, 20);
                }
                @include _992{
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 10px;
                    row-gap: 10px;
                }
            }
            .sertificate__item{
                width: 355px;
                height: 474px;
                @include _1920{
                    width: calcFluid(236.67, 355);
                    height: calcFluid(316, 474);
                }
                @include _992{
                    height: calcFluidMobile(178, 270);
                    width: 100%;
                    margin: 0 auto;
                }
                &:last-child:nth-child(2n+1){
                    @include _992{
                        grid-column: 1/-1;
                        width: 50%;
                    }
                }
            }
        }
        &.inline-4{
            grid-template-columns: 33% 1fr;
            column-gap: 20px; 
            @include _1920{
                column-gap: calcFluid(13.33, 20);
            }
            @include _992{
                grid-template-columns: 1fr;
                column-gap: 40px;
            }
            .sertificate__content{
                grid-template-columns: repeat(4, 1fr);
                column-gap: 20px;
                @include _1920{
                    column-gap: calcFluid(13.33, 20);
                }
                @include _992{
                    column-gap: 10px;
                    row-gap: 10px;
                    grid-template-columns: repeat(3, 1fr);
                }
                @include _576{
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            .sertificate__item{
                width: 255px;
                height: 340px;
                @include _1920{
                    width: calcFluid(170, 255);
                    height: calcFluid(226.67, 340);
                }
                @include _992{
                    height: calcFluidMobile(178, 270);
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
    &__info{
        &__title{
            margin-bottom: 33px;
            @include _1920{
                margin-bottom: calcFluid(22, 33);
            }
            @include _992{
                margin-bottom: 20px;
            }
            span{
                font-size: 48px;
                line-height: 55.2px;
                @include _1920{
                    font-size: calcFluid(32, 48);
                    line-height: calcFluid(36.80, 55.2);
                }
                @include _992{
                    font-size: 24px;
                    line-height: 27.6px;
                }
            }
        }
        &__description{
            margin-bottom: 32px;
            @include _1920{
                margin-bottom: calcFluid(21.33, 32);
            }
            @include _992{
                margin-bottom: 30px;
            }
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__button{
            width: 44.4%;
            @include _992{
                width: 100%;
            }
            span{

            }
        }
    }
    &__content{
        display: grid;
        row-gap: 45px;
        @include _1920{
            row-gap: calcFluid(30, 45);
        }
    }
    &__item{
        position: relative;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transition: all .3s ease;
        }
        &:hover{
            p{
                opacity: 1;
            }
            img{
                transform: scale(0.891);
            }
        }
        p{
            position: absolute;
            display: flex;
            height: 55px;
            opacity: 0;
            width: 55px;
            background-color: rgba(236, 28, 36, .8);
            border-radius: 50%;
            align-items: center; 
            justify-content: center;
            top: calc(50% - 28px);
            left: calc(50% - 28px);
            transition: opacity .3s ease;
            @include _1920{
                height: calcFluid(36.67, 55);
                width: calcFluid(36.67, 55);
            }
            span{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                width: 39px;
                height: 39px;
                border: 1px solid #fff;
                border-radius: 50%;
                font-size: 32px;
                font-weight: 100;
                @include _1920{
                    width: calcFluid(26, 39);
                    height: calcFluid(26, 39);
                    border: calcFluid(0.67, 1) solid #fff;
                    font-size: calcFluid(21.33, 32);
                }
            }
        }
    }
}