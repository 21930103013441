
.header{
    position: relative;
    z-index: 5;
    background-color: #fff;
    @include _1200{
        border-bottom: 1px solid #E3E3E3;
        @include _1920{
            border-bottom: calcFluid(0.67, 1) solid #E3E3E3;
        }
    }
}

.header-top{

    position: relative;

    display: flex;
    align-items: center;
    background-color: #636363;
    height: 50px;
    @include _1920{
        height: calcFluid(33.33, 50);
    }
    &__wrapper{
        width: 100%;
        justify-content: space-between;
    }

    .select_city--desktop_notify{
        position: absolute;
        top: 100%;
        z-index: 10;
    }
}

.header-menu{
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__cities{
        color: #fff;
        position: relative;
        &__marker{
            height: 16px;
            width: 13px;
            fill: transparent;
            stroke: #fff;
            margin-right: 7px;
            @include _1920{
                height: calcFluid(10.67, 16);
                width: calcFluid(8.67, 13);
                margin-right: calcFluid(4.67, 7);
            }
        }
        &__arrow{    
            margin-left: 4px;
            margin-top: 6px;
            width: 8px;
            height: 8px;
            fill: #fff;
            @include _1920{
                margin-left: calcFluid(2.67, 4);
                margin-top: calcFluid(4, 6);
                width: calcFluid(5.33, 8);
                height: calcFluid(5.33, 8);
            }
        }
        &__list{
            position: absolute;
            display: flex; 
            flex-direction: column;
            background-color: #fff;
            z-index: 2;
            width: 290px;
            margin-top: 14px;
            box-shadow: 0px 4px 16px 0px #0000001F;
            overflow: hidden;
            max-height: 0px;
            transition: all .3s ease;
            margin-left: -20px;
            @include _1920{
                width: calcFluid(193.33, 290);
                margin-top: calcFluid(9.33, 14);
                box-shadow: 0 calcFluid(2.67, 4) calcFluid(10.67, 16) 0 #0000001F;
            }
            &__wrapper{
                display: flex;
                flex-direction: column;
                padding: 24px 60px 30px 40px; 
                @include _1920{
                    padding: calcFluid(16, 24) calcFluid(40, 60) calcFluid(20, 30) calcFluid(26.67, 40);
                }
            }
            &__item{
                &:not(:last-child){
                    margin-bottom: 24px;
                    @include _1920{
                        margin-bottom: calcFluid(16, 24);
                    }
                }
                span{
                    font-size: 21px;
                    line-height: 26.25px;
                    color: #272727;
                    transition: color .3s ease;
                    @include _1920{
                        font-size: calcFluid(14, 21);
                        line-height: calcFluid(17.50, 26.25);
                    }
                }
                &:hover{
                    span{
                        color: #EC1C24;
                    }
                }
            }


        }
        
        &:hover{
            .header-menu__cities__list{
                max-height: 600px;
                // @include _1920{
                //     max-height: calcFluid(400, 600);
                // }
            }
        }
        &__select{
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all .3s ease;
            &:hover{
                span{
                    border-color: transparent;
                }
                .header-menu__cities__arrow{
                    transform: scale(-1);
                }
            }
            span{
                font-size: 18px;
                line-height: 22.5px;
                border-bottom: 2px dashed #fff;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                    border-bottom: calcFluid(1.33, 2) dashed #fff;
                }
            }
        }
    }
    &__nav{
        display: flex;
        &__item{
            position: relative;
            &:hover{
                svg{
                    transform: scaleY(-1);
                    opacity: .7;
                }
                .header-menu__nav__item__list{
                    max-height: 100vh;
                    @include _1920{
                        max-height: calcFluid(333.33, 500);
                    }
                }
            }
            a{
                &:hover{
                    span{
                        color: rgba(255,255,255, .7);
                    }
                }
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    color: #fff;
                    transition: color .3s ease;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                }
            }
            svg{
                width: 8px;
                height: 8px;
                fill: #fff;
                transition: all .3s ease;
                @include _1920{
                    width: calcFluid(5.33, 8);
                    height: calcFluid(5.33, 8);
                }
            }
            &:not(:last-child){
                margin-right: 55px;
                @include _1920{
                    margin-right: calcFluid(36.67, 55);
                }
            }
            &__list{
                position: absolute;
                margin-top: 12px;
                margin-left: -40px;
                background-color: #fff;
                z-index: 3;
                box-shadow: 0 4px 16px 0 #0000001f;
                overflow: hidden;
                max-height: 0px;
                transition: all .3s ease;
                @include _1920{
                    margin-top: calcFluid(8, 12);
                    margin-left: calcFluid(-26.67, -40);
                    box-shadow: 0 calcFluid(2.67, 4) calcFluid(10.67, 16) 0 #0000001f;
                }
                &__wrapper{
                    padding: 24px 60px 30px 40px;
                    display: flex;
                    flex-direction: column;
                    @include _1920{
                        padding: calcFluid(16, 24) calcFluid(40, 60) calcFluid(20, 30) calcFluid(26.67, 40);
                    }
                }
                &__item{
                    white-space: nowrap;
                    &:not(:last-child){
                        margin-bottom: 24px;
                        @include _1920{
                            margin-bottom: calcFluid(16, 24);
                        }
                    }
                    span{
                        font-size: 21px;
                        line-height: 26.25px;
                        color: #272727!important;
                        transition: all .3s ease;
                        @include _1920{
                            font-size: calcFluid(14, 21);
                            line-height: calcFluid(17.50, 26.25);
                        }
                    }
                    &:hover{
                        span{
                            color: $main-clr-hover!important;
                        }
                    }
                }
            }
        }
    }
}

.header-main{
    padding: 31px 0 34px;
    @include _1920{
        padding: calcFluid(20.67, 31) 0 calcFluid(22.67, 34);
    }
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.header-left{ 
    display: flex;

}

.header-right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    &__info{
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        @include _1920{
            margin-right: calcFluid(13.33, 20);
        }
        &__phone{
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            @include _1920{
                margin-bottom: calcFluid(4.67, 7);
            }
            &:hover{
                span{
                    color: $main-clr-hover;
                }
            }
            svg{
                width: 18px;
                height: 18px;
                margin-right: 9px;
                fill: #EC1C24;
                @include _1920{
                    width: calcFluid(12, 18);
                    height: calcFluid(12, 18);
                    margin-right: calcFluid(6, 9);
                }
            }
            span{
                color: #272727;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                transition: color .3s ease;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
            }
        }
        &__work-time{
            font-size: 18px;
            line-height: 22.5px;
            color: #636363;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }

        }

    }
    &__btn{
        height: 60px;
        width: 253px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include _1920{
            height: calcFluid(40, 60);
            width: calcFluid(168.67, 253);
        }
        span{
            font-size: 18px;
            @include _1920{
                font-size: calcFluid(12, 18);
            }
        }
    }
}

.header-center{
    display: flex;
    margin-right: 20px;
    @include _1920{
        margin-right: calcFluid(13.33, 20);
    }
    &__btn{
        display: grid;
        grid-template-columns: 40px 120px;
        column-gap: 6px;
        cursor: pointer;
        @include _1920{
            grid-template-columns: calcFluid(26.67, 40) calcFluid(80, 120);
            column-gap: calcFluid(4, 6);
        }
        &:first-child{
            margin-right: 55px;
            @include _1920{
                margin-right: calcFluid(36.67, 55);
            }
        }
        &:hover{
            svg{
                &.svg-stroke{
                    stroke: $main-clr-hover;
                }
                &.svg-fill{
                    fill: $main-clr-hover;
                }
            }
            span{
                color: $main-clr-hover;
            }
        }
        svg{
            width: 32px;
            height: 32px;
            margin: auto;
            transition: all .3s ease;
            @include _1920{
                width: calcFluid(21.33, 32);
                height: calcFluid(21.33, 32);
            }
            &.svg-stroke{
                fill: transparent;
                stroke: #EC1C24;
            }
            &.svg-fill{
                fill: #EC1C24;
            }
        }
        span{
            font-size: 18px;
            line-height: 22.5px;
            transition: all .3s ease;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
        }
    }
}

.header-logo{
    margin-right: 45px;
    @include _1920{
        margin-right: calcFluid(30, 45);
    }
    svg{
        width: 255px;
        height: 56px;
        @include _1920{
            width: calcFluid(170, 255);
            height: calcFluid(37.33, 56);
        }
        @include _992{
            width: 134px;
            height: 30px;
        }
    }
}

.header-search{
    max-width: 470px !important;
    margin-right: 25px;
    @include _1920{
        max-width: calcFluid(313.33, 470)!important;
        margin-right: calcFluid(16.67, 25);
    }
}
.header-bottom{
    background-color: #FFFBD4;
    &__wrapper{
        display: flex;
        justify-content: space-between;
    }
    &__catalog-item{
        padding: 15px 0 19px;
        cursor: default;
        @include _1920{
            padding: calcFluid(10, 15) 0 calcFluid(12.67, 19);
        }
        &:hover{
            .header-bottom__arrow{
                transform: scaleY(-1);
                opacity: .7;
            }
            .menu{
                max-height: 100vh;
                box-shadow: 0 4px 16px 0 #0000001f;
            }
            span{
                color: #ec1c24;
            }
        }
        svg{
            fill: transparent;
            width: 26px;
            height: 26px;
            margin-right: 4px;
            transition: all .3s ease;
            @include _1920{
                width: calcFluid(17.33, 26);
                height: calcFluid(17.33, 26);
                margin-right: calcFluid(2.67, 4);
            }
        }
    }
    &__arrow{
        width: 10px!important;
        height: 10px!important;
        margin: 0px!important;
        fill: #000!important;
        stroke: transparent!important;
        @include _1920{
            width: calcFluid(6.67, 10)!important;
            height: calcFluid(6.67, 10)!important;
        }
    }
}
.menu{
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 19px;
    @include _1920{
        margin-top: calcFluid(12.67, 19);
    }

    overflow: hidden;
    max-height: 0px;
    transition: all .3s ease;
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding: 37px 0 60px;
        column-gap: 15px;
        @include _1920{
            padding: calcFluid(24.67, 37) 0 calcFluid(40, 60);
            column-gap: calcFluid(10, 15);
        }
    }
    &__list{
        &__item{
            display: block;
            span{
                font-size: 21px;
                line-height: 26.25px; 
                opacity: 1!important;
                transition: color .3s ease;
                color: #272727!important;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(17.50, 26.25);
                }
            }
            &:hover{
                span{
                    color: $main-clr-hover!important;
                }
            }
            &:not(:last-child){
                margin-bottom: 24px;
                @include _1920{
                    margin-bottom: calcFluid(16, 24);
                }
            }
            &.disabled{
                cursor: default;
                &:hover{
                    span{
                        color: $text-clr!important;
                    }
                }
            }
            &.title{
                padding-bottom: 9px;
                margin-bottom: 21px;
                grid-column: 1/-1;
                border-bottom: 2px solid #EC1C24;
                @include _1920{
                    margin-bottom: calcFluid(14, 21);
                    padding-bottom: calcFluid(6, 9);
                }
                span{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                    opacity: 1;
                    color: #272727;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                }
            }
        }
        &.double{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10px;
        }
    }
}


.header-sticky{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: -240px;
    width: 100%;
    transition: all .3s ease;
    box-shadow: 0px 4px 16px 0px #0000001F;
    @include _1920{
        top: calcFluid(-160, -240);
        box-shadow: 0 calcFluid(2.67, 4) calcFluid(10.67, 16) 0 #0000001F;
    }

    &.active{
        top: 0px;
    }
    &__main{
        display: grid;
        width: 100%;
        grid-template-columns: 33% 22% 1fr;
        column-gap: 97px;
        height: 104px;
        z-index: 1;
        background-color: #fff;
        @include _1920{
            column-gap: calcFluid(64.67, 97);
            height: calcFluid(69.33, 104);
        }
    }
    &__logo-cities{
        display: flex;
        align-items: center;

        &__logo{
            display: flex;
            margin-right: 45px;
            align-items: center;
            @include _1920{
                margin-right: calcFluid(30, 45);
            }
            svg{
                width: 255px;
                height: 56px;
                @include _1920{
                    width: calcFluid(170, 255);
                    height: calcFluid(37.33, 56);
                }
            }
        }
        &__cities{
            display: flex;
            align-items: center;
        }
    }
    &__activity{
        display: flex;
        align-items: center;
        &__button{
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
                width: 50px;
                height: 50px;
                margin-right: 13px;
                transition: all .3s ease;
                @include _1920{
                    width: calcFluid(33.33, 50);
                    height: calcFluid(33.33, 50);
                    margin-right: calcFluid(8.67, 13);
                }

            }
            span{
                font-size: 18px;
                line-height: 19.8px;
                transition: color .3s ease;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(13.20, 19.8);
                }
            }
            .svg-fill{
                stroke: transparent;
                fill: $main-clr;
            }
            .svg-stroke{
                stroke: $main-clr;
                fill: transparent;
            }

            &:first-child{
                margin-right: 60px;
                @include _1920{
                    margin-right: calcFluid(40, 60);
                }
            }
            &:hover{
                span{
                    color: $main-clr-hover;
                }
                .svg-fill{
                    fill: $main-clr-hover;
                }
                .svg-stroke{
                    stroke: $main-clr-hover;
                }
            }
        }
    }
    &__last{
        display: flex;
        align-items: center;
        margin-left: auto;
        &__info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 20px;
            @include _1920{
                margin-right: calcFluid(13.33, 20);
            }
            &__phone{
                &:hover{
                    span{
                        color: $main-clr-hover
                    }
                }
                svg{
                    width: 19px;
                    height: 19px;
                    margin-right: 3px;
                    fill: #ec1c24;
                    @include _1920{
                        width: calcFluid(12.67, 19);
                        height: calcFluid(12.67, 19);
                        margin-right: calcFluid(2, 3);
                    }
                }
                span{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 30px;
                    transition: all .3s ease;
                    white-space: nowrap;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                }
            }
            &__work{
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    color: #636363;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                }
            }
        }
        &__btn{
            margin-right: 30px;
            height: 60px;
            width: 255px;
            @include _1920{
                margin-right: calcFluid(20, 30);
                height: calcFluid(40, 60);
                width: calcFluid(170, 255);
            }
            span{
                font-size: 18px;
                line-height: 22.5px;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
            }

        }
        &__menu-btn{
            position: relative;
            border: 1px solid $main-clr;
            border-radius: 50%;
            height: 60px;
            width: 60px;
            cursor: pointer;
            @include _1920{
                height: calcFluid(40, 60);
                width: calcFluid(40, 60);
            }
            &:hover{
                border-color: $main-clr-hover;
                span{
                    background-color: $main-clr-hover;
                }
            }
            &.active{
                span{
                    width: 0px;
                    &:first-child{
                        width: 28px;
                        top: 50%;
                        transform: rotate(45deg);
                        @include _1920{
                            width: calcFluid(18.67, 28);
                        }
                    }
                    &:last-child{
                        width: 28px;
                        top: 50%;
                        transform: rotate(-45deg);
                        @include _1920{
                            width: calcFluid(18.67, 28);
                        }
                    }
                }
            }

            span{
                display: block;
                position: absolute;
                width: 28px;
                height: 1px;
                background-color: $main-clr;
                transition: all .3s ease;
                left: calc(50% - 14px);
                top: 50%;
                @include _1920{
                    width: calcFluid(18.67, 28);
                    height: calcFluid(0.67, 1);
                    left: calc(50% - 9.33px);
                }
                &:first-child{
                    top: 35%;
                }
                &:last-child{
                    top: 65%;
                }
            }
        }
    }
    &__menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #FFFBD4; 
        width: 100%;
        max-height: 0px;
        transition: all .3s ease;   
        overflow: hidden;
        &.active{
            max-height: 400px;
            @include _1920{
                max-height: calcFluid(266.67, 400);
            }
        }
    }
    &__top{
        display: grid;
        grid-template-columns: 20% 1fr;
        width: 100%;
        height: 67px;
        align-items: center;
        @include _1920{
            height: calcFluid(44.67, 67);
        }
        &__links{
            display: flex;
            justify-content: end;
            &__item{
                $this: &;
                &:hover{
                    span{
                        opacity: .7;
                    }
                    svg{
                        opacity: .7;
                        transform: scaleY(-1);
                    }
                    #{$this}__list{
                        max-height: 100vh;
                    }
                }
                &:not(:last-child){
                    margin-right: 60px;
                    @include _1920{
                        margin-right: calcFluid(40, 60);
                    }
                }
                svg{
                    width: 8px;
                    height: 8px;
                    transition: all .3s ease;
                    @include _1920{
                        width: calcFluid(5.33, 8);
                        height: calcFluid(5.33, 8);
                    }
                }
                span{
                    font-size: 18px;
                    line-height: 22.5px;
                    transition: opacity .3s ease;
                    @include _1920{
                        font-size: calcFluid(12, 18);
                        line-height: calcFluid(15, 22.5);
                    }
                }
                &__list{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    max-height: 0px;
                    overflow: hidden;
                    margin-left: -20px;
                    box-shadow: 0 4px 16px 0 #0000001f;
                    @include _1920{
                        margin-left: calcFluid(-13.33, -20);
                    }
                    a{
                        display: flex;
                        background-color: #fff;
                        padding: 10px 20px;
                        transition: all .3s ease;
                        @include _1920{
                            padding: calcFluid(6.67, 10) calcFluid(13.33, 20);
                        }
                        &:hover{
                            background-color: rgba(245, 245, 245, 1);
                            span{
                                opacity: 1;
                            }
                        }
                        span{
                            opacity: 1!important;
                        }
                    }
                }
            }
        }
        &__search-line{
            .search-line{
                height: 50px;
                background-color: #fff;
                @include _1920{
                    height: calcFluid(33.33, 50);
                }
                button{
                    background-color: #fff;
                }
            }
        }
    }
}
.cities{
    position: relative;
    &:hover{
        .cities__list{
            max-height: 100vh;

        }
    }
    &__marker{
        width: 13px;
        height: 16px;
        margin-right: 6px;
        fill: transparent;
        stroke: #636363;
        @include _1920{
            width: calcFluid(8.67, 13);
            height: calcFluid(10.67, 16);
            margin-right: calcFluid(4, 6);
        }
    }
    &__select{
        display: flex;
        margin-right: 5px;
        cursor: default;
        @include _1920{
            margin-right: calcFluid(3.33, 5);
        }
        span{
            font-size: 18px;
            line-height: 22.5px;
            color: #636363;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
        }
    }
    &__arrow{
        width: 8px;
        height: 8px;
        @include _1920{
            width: calcFluid(5.33, 8);
            height: calcFluid(5.33, 8);
        }
    }
    &__list{
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        min-width: 100%;
        box-shadow: 0px 4px 16px 0px #0000001F;
        z-index: 2;
        overflow: hidden;
        max-height: 0px;
        top: 30px;
        transition: all .3s ease;
        &__item{
            padding: 10px 20px;
            white-space: nowrap;
            @include _1920{
                padding: calcFluid(6.67, 10) calcFluid(13.33, 20);
            }
            span{
                transition: color .3s ease;
            }
            &:hover{
                span{
                    color: $main-clr-hover;
                }
            }
        }
    }

}