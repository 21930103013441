@function calcFluid($f-min, $f-max, $base: 0, $v-min: 1280, $v-max: 1920) {
    $k: ($f-max - $f-min)/($v-max - $v-min);
    $b: $f-min - $k * $v-min;
 
    $b: $b * 1px;

    @if $base == 0{
        @return calc(#{$k} * 100vw + #{$b} );
    }
    @else{
        @return calc( #{$base} - (#{$k} * 100vw + #{$b}) );
    }
}

@function calcFluidAll($f-min, $f-max){
    @return calcFluid($f-min, $f-max, 0, 320, 1920);
}

@function calcFluidMobile($f-min, $f-max, $base: 0, $v-min: 320, $v-max: 769) {
    $k: ($f-max - $f-min)/($v-max - $v-min);
    $b: $f-min - $k * $v-min;
 
    $b: $b * 1px;

    @if $base == 0{
        @return calc(#{$k} * 100vw + #{$b} );
    }
    @else{
        @return calc( #{$base} - (#{$k} * 100vw + #{$b}) );
    }
}

@function calcFluidP($f-min, $f-max, $v-min: 320, $v-max: 1920, $percent: 100) {
	$k: ($f-max - $f-min)/($v-max - $v-min);
	$b: $f-min - $k * $v-min;
  
	$b: $b * 1px;

	$p: $percent * 1%;
  
	@return calc( #{$p} + (#{$k} * 100vw + #{$b}) );
}
