.portfolio{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            transition: color .3s ease;
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 0px;
            grid-template-rows: repeat(3, auto);
            grid-auto-rows: 0;
            overflow: hidden;    
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }

        &.opened{
            grid-auto-rows: auto;
        }
    }
    &__item{
        display: flex;
        flex-direction: column;

        border-radius: 20px;
        border: 1px solid #C9C9C9;
        transition: all .3s ease;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            border: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border-radius: 20px;
            margin-bottom: 20px;
        }

        &__image{
            width: 100%;
            height: 360px;
            margin-bottom: 21px;
            @include _1920{
                height: calcFluid(240, 360);
                margin-bottom: calcFluid(14, 21);
            }
            @include _992{
                height: 180px;
                margin-bottom: 18px;
            }

            img{
                width: 100%;
                height: 100%;
                border-radius: 20px 20px 0 0;
                object-fit: cover;
                @include _1920{
                    border-radius: calcFluid(13.33, 20) calcFluid(13.33, 20) 0 0;
                }
                @include _992{
                    border-radius: 20px 20px 0 0;
                }
            }
        }
        &__title{
            padding: 0 30px 38px 35px;
            @include _1920{
                padding: 0 calcFluid(20, 30) calcFluid(25.33, 38) calcFluid(23.33, 35);
            }
            @include _992{
                padding: 0 24px 30px;
            }
            span{
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &:hover{
            box-shadow: 0px 2px 24px 0px #0000002E;
            border-color: transparent;

            .portfolio__item__title{
                span{
                    color: $main-clr;
                }
            }
        }
    }
}
.portfolio-gallery{
    display: grid;
    column-gap: 45px;
    row-gap: 45px;
    @include _1920{
        column-gap: calcFluid(30, 45);
        row-gap: calcFluid(30, 45);
    }
    @include _992{
        column-gap: 10px;
        row-gap: 20px;
    }


    &.gallery-2{
        grid-template-columns: repeat(2, 1fr);
        @include _992{
            grid-template-columns: 1fr;
        }
        img{
            height: 560px;
            @include _1920{
                height: calcFluid(373.33, 560);
            }
            @include _992{
                height: calcFluidMobile(170,370);
            }
        }
    }
    &.gallery-3{
        grid-template-columns: repeat(3, 1fr);
        @include _992{
            grid-template-columns: repeat(2, 1fr);
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
        img{
            height: 340px;
            @include _1920{
                height: calcFluid(226.67, 340);
            }
            @include _992{
                height: calcFluidMobile(170, 250);
            }
        }
    }
    &.gallery-4{
        grid-template-columns: repeat(4, 1fr);
        @include _992{
            grid-template-columns: repeat(3, 1fr);
        }
        @include _576{
            grid-template-columns: repeat(2, 1fr);
        }
        img{
            height: 248px;
            @include _1920{
                height: calcFluid(165.33, 248);
            }
            @include _992{
                height: calcFluidMobile(82, 164);
            }
        }
    }

    &__item{
        &__image{
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 11px;
            cursor: pointer;
            width: 100%;
            @include _1920{
                margin-bottom: calcFluid(7.33, 11);
            }
            &:hover{
                p{
                    opacity: 1;
                }
                img{
                    transform: scale(0.891);
                }
            }
            img{
                width: 100%;
                border-radius: 20px;
                transition: transform .3s ease;
                object-fit: cover;
                @include _1920{
                    border-radius: calcFluid(13.33, 20);
                }
            }
            p{
                position: absolute;
                display: flex;
                height: 55px;
                opacity: 0;
                width: 55px;
                background-color: rgba(236, 28, 36, .8);
                border-radius: 50%;
                align-items: center; 
                justify-content: center;
                top: calc(50% - 28px);
                left: calc(50% - 28px);
                transition: opacity .3s ease;
                @include _1920{
                    height: calcFluid(36.67, 55);
                    width: calcFluid(36.67, 55);
                }
                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    width: 39px;
                    height: 39px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    font-size: 32px;
                    font-weight: 100;
                    @include _1920{
                        width: calcFluid(26, 39);
                        height: calcFluid(26, 39);
                        border: calcFluid(0.67, 1) solid #fff;
                        font-size: calcFluid(21.33, 32);
                    }
                }
            }
        }
        &__title{
            margin-left: 10px;
            @include _1920{
                margin-left: calcFluid(6.67, 10);
            }
            @include _992{
                margin-left: 5px;
            }
            span{
                font-size: 18px;
                line-height: 22.5px;
                color: #636363;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
                @include _992{
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
}