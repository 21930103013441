.heading{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 20px;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid #C9C9C9;
        transition: all .3s ease;
        @include _1920{
            border-radius: calcFluid(13.33, 20);
            border: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border-radius: 20px;
        }
        &:last-child:nth-child(2n+1){
            @include _992{
                width: 50%;
                margin: 0 auto;
                grid-column: 1/-1;
            }
            @include _576{
                width: 100%;
            }
        }
        &:hover{
            border-color: transparent;
            box-shadow: 0px 2px 24px 0px #0000002E;
            .heading__item__title{
                span{
                    color: $main-clr;
                }
            }
        }
        &__image{
            width: 100%;
            height: 360px;
            @include _1920{
                height: calcFuid(240,360);
            } 
            @include _992{
                height: calcFluidMobile(180, 230);
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__date{
            padding: 0 45px;
            margin-bottom: 14px;
            margin-top: 25px;
            @include _1920{
                padding: 0 calcFluid(30, 45);
                margin-bottom: calcFluid(9.33, 14);
                margin-top: calcFluid(16.67, 25);
            }
            @include _992{
                padding: 0 24px;
                margin-bottom: 12px;
                margin-top: 18px;
                
            }
            span{
                font-size: 18px;
                line-height: 22.5px;
                color: #636363;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__title{
            padding: 0 45px;
            margin-bottom: 21px;
            @include _1920{
                padding: 0 calcFluid(30, 45);
                margin-bottom: calcFluid(14, 21);
            }
            @include _992{
                padding: 0 24px;
                margin-bottom: 14px;
            }
            span{
                transition: color .3s ease;
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 22.5px;
                }
            }
        }
        &__text{
            padding: 0 45px;
            padding-bottom: 80px;
            @include _1920{
                padding: 0 calcFluid(30, 45);
                padding-bottom: calcFluid(53.33, 80);
            }
            @include _992{
                padding: 0 24px;
                padding-bottom: 35px;
            }
            span{
                -webkit-line-clamp: 5;
                overflow: hidden;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &.title{
            .heading__item{
                &__date{
                    margin-bottom: 21px;
                    margin-top: 45px;
                    @include _1920{
                        margin-bottom: calcFluid(14, 21);
                        margin-top: calcFluid(30, 45);
                    }
                    @include _992{
                        margin-bottom: 12px;
                        margin-top: 30px;
                    }
                    span{
                        font-size: 21px;
                        line-height: 26.25px;
                        @include _1920{
                            font-size: calcFluid(14, 21);
                            line-height: calcFluid(17.50, 26.25);
                        }
                        @include _992{
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}