.header-mobile{
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &__call{
        margin-left: auto;
        svg{
            height: 18px;
            width: 18px;
            fill: $main-clr;
        }
    }
}
.mob-nav-arr{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    margin-right: 0px;
}
.header-burger{
    position: relative;
    width: 20px;
    height: 16px;
    margin-left: 30px;
    span{
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $main-clr;
        transition: $tr-time;
        transition-property: top, bottom, opacity, transform;
        &:nth-child(1){
            top: 0;
        }
        &:nth-child(2){
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:nth-child(3){
            bottom: 0;
        }
    }
    &.opened{
        span{
            &:nth-child(1){
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
}

.mobile-menu{
    position: fixed;
    z-index: 99;
    top: 55px;
    bottom: 0;
    left: 100%;
    width: 100%;
    background-color: #fff;
    overflow: scroll;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) $tr-time;
    border-top: 1px solid #D0D0D0;
    &.opened{
        left: 0%;
    }

    .header-button{
        flex-direction: row;
        padding: 20px;
        
        &:not(:last-child) {
            margin: 0;
        }
        svg{
            margin: 0 12px 0 0;
        }
        @include _1200{
            &:not(:last-child){
                border-right: 1px solid #fff;
            }
        }
        @include _576{
            &:not(:last-child){
                border-right: none;
            }
            &:nth-child(odd){
                border-right: 1px solid #fff;
            }
            &:nth-child(1),
            &:nth-child(2){
                border-bottom: 1px solid #fff;
            }
        }
    }

    .mobile-nav{
        position: absolute;
        left: 100%;
        margin-top: 20px;
        transition: all .3s ease;
        &.active{
            left: 0;
        }
        &.active-rel{
            position: relative;
        }
        ul{
            padding: 0 21px;
        }
        &__city{
            display: flex;
            align-items: center;
            svg{
                width: 13px;
                height: 16px;
                stroke: $main-clr;;
                fill: #fff;
                margin-right: 10px;
            }
            span{
                font-size: 16px;
                line-height: 20px;
                border-bottom: 1px dashed $main-clr;
                color: $main-clr;
            }
        }
        &__item{
            display: flex;
            align-items: center;
            margin-top: 20px;
            p{
                display: flex;
            }
            a{
                display: flex;
                align-items: center;
            }
            svg{
                width: 20px;
                height: 20px;
                margin-right: 9px;
            }
            &__arrow{
                width:  8px!important;
                height: 8px!important;
                transform: rotate(-90deg);
            }
            span{
                font-size: 16px;
                line-height: 20px;
                margin-right: 8px;
            }

            &.mobile_nav__item-first{
                justify-content: space-between;
                p{
                    justify-content: space-between;
                    width: 100%;
                    margin-right: 0;
                }

                .mob-nav-arr{
                    padding: 0;
                    margin: 0;
                }
            }

            &.mobile_nav__item-second{
                p {
                    justify-content: space-between;
                    flex-grow: 1;
            
                }
            
                .mob-nav-arr{
                    padding: 0;
                    margin: 0;
                }
                
            }

        }
        &__back{
            display: flex;
            align-items: center;
            opacity: .6;
            svg{
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
            span{
                font-size: 16px;
                line-height: 20px;
            }
        }
        &__buttons{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            padding: 0 21px;
            margin-top: 42px;
            &__item{
                display: grid;
                grid-template-columns: calcFluidMobile(29, 45) 1fr;
                column-gap: 12px;
                align-items: center;

                svg{
                    width: calcFluidMobile(29, 45);
                    height: calcFluidMobile(29, 45);
                    &.fill{
                        fill: $main-clr;
                    }
                    &.stroke{
                        stroke: $main-clr;
                        fill: transparent;
                    }
                }
                span{
                    font-size: calcFluidMobile(12, 14);
                    line-height: 15px;
                }
            }
        }
        &__info{
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            padding: 0 21px;
            &__phone{    
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                span{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                }
                svg{
                    width: 20px;
                    height: 20px;
                    fill: $main-clr;
                    margin-right: 8px;
                }
            }
            &__work{
                span{
                    font-size: 16px;
                    line-height: 20px;
                    color: #636363;
                }
            }
        }
        &__call{
            
            margin: 24px 21px 0;
        }
    }

    .mobile-main{
        background-color: #fff;
        padding: 0 0 25px;
        position: relative;
        overflow: hidden;
    
    }

    .mobile-search{
        background-color: #fff;
        padding: 0 21px;
        margin-top: 20px;
        .search-line{
            width: 100%;
            background-color: #fff;
            // border-radius: 40px;
            border: 1px solid #A9A9A9;
            button{
                background-color: transparent;
            }
        }
    }
}