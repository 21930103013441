@import 'vendors/swiper.min';

@import 'base/app';
@import 'components/category-card';
@import 'components/countdown';
@import 'components/product-card';
@import 'components/content-text';


.index-catalog{
    @include _1200{
        display: none;
    }
}