.tariff{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 40px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 30px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;

            }
        }
    }
    &__wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            grid-template-columns: repeat(2, 1fr);
            row-gap: 20px;
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item{
        border: 1px solid #C9C9C9;
        border-radius: 20px;
        transition: all .3s ease;
        @include _1920{
            border: calcFluid(0.67, 1) solid #C9C9C9;
            border-radius: calcFluid(13.33, 20);
        }
        @include _992{
            border-radius: 20px;
        }
        &__image{
            width: 100%;
            height: 262px;
            margin-bottom: 21px;
            @include _1920{
                height: calcFluid(174.67, 262);
                margin-bottom: calcFluid(14, 21);
            }
            @include _992{
                height: 180px;
                margin-bottom: 18px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px 20px 0 0;
                @include _1920{
                    border-radius: calcFluid(13.33, 20) calcFluid(13.33, 20) 0 0;
                }
                @include _992{
                    border-radius: 20px 20px 0 0;
                }
            }
        }
        &__title{
            margin-bottom: 14px;
            padding: 0 30px;
            @include _1920{
                margin-bottom: calcFluid(9.33, 14);
                padding: 0 calcFluid(20, 30);
            }
            @include _992{
                margin-bottom: 8px;
                padding: 0 24px;
            }
            span{
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                @include _1920{
                    font-size: calcFluid(16, 24);
                    line-height: calcFluid(20, 30);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 24.5px;
                }
            }
        }
        &__price{
            padding: 0 30px 30px 30px;
            @include _1920{
                padding: 0 calcFluid(20, 30) calcFluid(20, 30) calcFluid(20, 30);
            }
            @include _992{
                padding: 0 24px 30px;
            }
            span{
                font-size: 21px;
                line-height: 26.25px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(17.50, 26.25);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        &:hover{
            border-color: transparent;
            box-shadow: 0px 2px 24px 0px #0000002E;
            .tariff__item__title{
                span{
                    color: $main-clr;
                }
            }
        }
    }
}