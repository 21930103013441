.calculator{
    background-color: #F5F5F5;
    padding: 70px 0 120px;
    @include _1920{
        padding: calcFluid(46.67, 70) 0 calcFluid(80, 120);
    }
    @include _992{
        padding: 60px 0 60px;
        margin-bottom: 60px;
    }
    &__wrapper{
    }
    &__info{
        &__title{
            margin-bottom: 32px;
            @include _1920{
                margin-bottom: calcFluid(21.33, 32);
            }
            @include _992{
                margin-bottom: 18px;
            }
            span{
                font-size: 48px;
                line-height: 55.2px;
                @include _1920{
                    font-size: calcFluid(32, 48);
                    line-height: calcFluid(36.80, 55.2);
                }
                @include _992{
                    font-size: 24px;
                    line-height: 27.6px;
                }
            }
        }
        &__text{
            margin-bottom: 45px;
            width: 55%;
            @include _1920{
                margin-bottom: calcFluid(30, 45);
            }
            @include _992{
                margin-bottom: 25px;
                width: 100%;
            }
            span{
                font-size: 21px;
                line-height: 31.5px;
                @include _1920{
                    font-size: calcFluid(14, 21);
                    line-height: calcFluid(21, 31.5);
                }
                @include _992{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    &__AQ{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include _992{
            grid-template-columns: 1fr;
            row-gap: 30px;
        }

        &__question{
            margin-right: 125px;
            @include _1920{
                margin-right: calcFluid(83.33, 125);
            }
            @include _992{
                margin-right: 0px;
            }
            &__text{
                margin-bottom: 22px;
                @include _1920{
                    margin-bottom: calcFluid(14.67, 22);
                }
                @include _992{
                    margin-bottom: 16px;
                }
                span{
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 600;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                    @include _992{
                        font-size: 18px;
                        line-height: 22.5px;
                    }
                }
            }
            &__answer{
                border-color: #A9A9A9;
                background-color: transparent;
                
                &.active{
                    border-color: $main-clr;
                }
                &:hover{
                    background-color: transparent;
                    border-color: $main-clr;
                    span{
                        color: rgba(39, 39, 39, 0.85);
                    }
                }
                &:not(:last-child){
                    margin-bottom: 20px;
                    @include _1920{
                        margin-bottom: calcFluid(13.33, 20);
                    }
                }
                span{
                    color: rgba(39, 39, 39, 0.85);
                }
                .active{
                    border-color: #EC1C24;
                }
            }
        }
    }
    &__result{
        margin-top: 45px;
        max-height: 0px;
        overflow: hidden;
        transition: all .3s ease;
        &.active{
            max-height: 100%;
        }
        @include _1920{
            margin-top: calcFluid(30, 45);
        }
        @include _992{
            margin-top: 40px;
        }

    }
}
.result{
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #C9C9C9;
    padding:  0 40px;
    @include _1920{
        border-radius: calcFluid(13.33, 20);
        border: calcFluid(0.67, 1) solid #C9C9C9;
        padding: 0 calcFluid(26.67, 40);
    }
    @include _992{
        background-color: transparent;
        border: none;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 19.3% 32%;
        @include _992{
            grid-template-columns: 1fr;
        }

    }
    &__info{
        display: flex;
        border-right: 1px solid #C9C9C9;
        padding: 40px 0;
        @include _1920{
            border-right: calcFluid(0.67, 1) solid #C9C9C9;
            padding: calcFluid(26.67, 40) 0;
        }
        @include _992{
            flex-direction: column;
            border: none;
            padding: 0;
        }
        &__left{
            span{
                font-size: 27px;
                line-height: 33.75px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
                &:last-child{
                    font-size: 24px;
                    line-height: 30px;
                    @include _1920{
                        font-size: calcFluid(16, 24);
                        line-height: calcFluid(20, 30);
                    }
                    @include _992{
                        font-size: 18px;
                        line-height: 22.5px;
                    }

                }
            }
            img{
                width: 300px;
                height: 188px;
                border-radius: 10px;
                margin: 24px 0 20px;
                @include _1920{
                    width: calcFluid(200, 300);
                    height: calcFluid(125.33, 188);
                    border-radius: calcFluid(6.67, 10);
                    margin: calcFluid(16, 24) 0 calcFluid(13.33, 20);
                }
                @include _992{
                    width: 100%;
                    height: calcFluidMobile(174, 348);
                    border-radius: 15px;
                    margin: 14px 0 18px;
                }
            }

        }
        &__right{
            padding: 60px 40px;
            @include _1920{
                padding: calcFluid(40, 60) calcFluid(26.67, 40);
            }
            @include _992{
                margin-top: 18px;
                padding: 0;
            }
            ul{
                li{
                    display: flex;
                    font-size: 21px;
                    line-height: 31.5px;
                    @include _1920{
                        font-size: calcFluid(14, 21);
                        line-height: calcFluid(21, 31.5);
                    }
                    @include _992{
                        font-size: 16px;
                        line-height: 24px;
                    }
                    &:not(:last-child){
                        margin-bottom: 20px;
                        @include _1920{
                            margin-bottom: calcFluid(13.33, 20);
                        }
                        @include _992{
                            margin-bottom: 15px;
                        }
                    }
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 3px;
                        background-color: $main-clr;
                        margin-right: 15px;
                        margin-top: 10px;
                        @include _1920{
                            width: calcFluid(8, 12);
                            height: calcFluid(8, 12);
                            border-radius: calcFluid(2, 3);
                            margin-right: calcFluid(10, 15);
                            margin-top: calcFluid(6.67, 10);
                        }
                        @include _992{
                            width: 8px;
                            height: 8px;
                            border-radius: 2px;
                            margin-right: 10px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
    &__variations{
        border-right: 1px solid #C9C9C9;
        @include _1920{
            border-right: calcFluid(0.67, 1) solid #C9C9C9;
        }
        @include _992{
            border: 1px solid #c9c9c9;
            border-radius: 15px;
            margin-top: 40px;
        }
        &__item{
            padding: 40px 36px 43px;
            @include _1920{
                padding: calcFluid(26.67, 40) calcFluid(24, 36) calcFluid(28.67, 43);
            }
            @include _992{
                padding: 20px 30px 28px ;
            }
            &:not(:last-child){
                border-bottom: 1px solid #A9A9A9;
                @include _1920{
                    border-bottom: calcFluid(0.67, 1) solid #A9A9A9;
                }
                @include _992{
                    border-bottom: calcFluid(0.67, 1) solid #C9C9C9;
                }
            }
            &__title{
                margin-bottom: 7px;
                @include _1920{
                    margin-bottom: calcFluid(4.67, 7);
                }
                @include _992{
                    margin-bottom: 7px;
                }
            }
            &__price{
                span{
                    font-size: 27px;
                    line-height: 33.75px;
                    @include _1920{
                        font-size: calcFluid(18, 27);
                        line-height: calcFluid(22.50, 33.75);
                    }
                    @include _992{
                        font-size: 20px;
                        line-height: 25px;
                    }
                    &:last-child{
                        font-size: 21px;
                        line-height: 26.25px;
                        color: #A9A9A9;
                        text-decoration-line: line-through;
                        margin-left: 10px;
                        @include _1920{
                            font-size: calcFluid(14, 21);
                            line-height: calcFluid(17.50, 26.25);
                            margin-left: calcFluid(6.67, 10);
                        }
                        @include _992{
                            font-size: 16px;
                            line-height: 20px;
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }
    &__form{
        position: relative;
        padding: 50px 0 40px 60px;

        @include _1920{
            padding: calcFluid(33.33, 50) 0 calcFluid(26.67, 40) calcFluid(40, 60);
        }
        @include _992{
            padding: 0;
            margin-top: 40px;
        }
        &__title{
            text-align: center;
            margin-bottom: 19px;
            @include _1920{
                margin-bottom: calcFluid(12.67, 19);
            }
            @include _992{
                margin-bottom: 20px;
            }
            span{
                font-size: 27px;
                line-height: 33.75px;
                font-weight: 600;
                @include _1920{
                    font-size: calcFluid(18, 27);
                    line-height: calcFluid(22.50, 33.75);
                }
                @include _992{
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        &__input{    
            display: flex;
            height: 84px;
            margin-bottom: 4px;
            @include _1920{
                height: calcFluid(56, 84);
                margin-bottom: calcFluid(2.67, 4);
            }
        }
        &__btn{
            margin-bottom: 12px;
            width: 100%;
            @include _1920{
                margin-bottom: calcFluid(8, 12);
            }
        }
        &__personal{
            text-align: center;
            span{
                font-size: 18px;
                line-height: 22.5px;
                @include _1920{
                    font-size: calcFluid(12, 18);
                    line-height: calcFluid(15, 22.5);
                }
                @include _992{
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 15px;
                }
                span{
                    color: #A9A9A9;
                }
                a{
                    color: #636363;
                }
            }
        }
    }
}