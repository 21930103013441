.documents{
    margin-bottom: 100px;
    @include _1920{
        margin-bottom: calcFluid(66.67, 100);
    }
    @include _992{
        margin-bottom: 60px;
    }
    &__title{
        margin-bottom: 45px;
        @include _1920{
            margin-bottom: calcFluid(30, 45);
        }
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-size: 48px;
            line-height: 55.2px;
            @include _1920{
                font-size: calcFluid(32, 48);
                line-height: calcFluid(36.80, 55.2);
            }
            @include _992{
                font-size: 24px;
                line-height: 27.6px;
            }
        }
    }
    &__wrapper{
        display: grid;
        column-gap: 45px;
        row-gap: 45px;
        @include _1920{
            column-gap: calcFluid(30, 45);
            row-gap: calcFluid(30, 45);
        }
        @include _992{
            row-gap: 14px;
        }
        &.inline-3{
            grid-template-columns: repeat(3, 1fr);
            @include _992{
                grid-template-columns: repeat(2, 1fr);
            }
            @include _576{
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &.inline-4{
            grid-template-columns: repeat(4, 1fr);
            @include _992{
                grid-template-columns: repeat(2, 1fr);
            }
            @include _576{
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    &__item{
        border: 1px solid #C9C9C9;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 24px 34px 36px 34px;
        transition: all .3s ease;
        @include _1920{
            border: calcFluid(0.67, 1) solid #C9C9C9;
            border-radius: calcFluid(13.33, 20);
            padding: calcFluid(16, 24) calcFluid(22.67, 34) calcFluid(24, 36) calcFluid(22.67, 34);
        }
        @include _992{
            border-radius: 20px;
            padding: 14px 24px 20px;
        }
        &:hover{
            border-color: transparent;
            box-shadow: 0px 2px 24px 0px #0000002E;
            svg{
                fill: $main-clr;
            }
            .documents__info{
                span{
                    color: $main-clr;
                }
            }
        }
    }
    &__info{
        display: grid;
        grid-template-columns: 27px 1fr;
        column-gap: 18px;
        margin-bottom: 11px;
        @include _1920{
            grid-template-columns: calcFluid(18, 27) 1fr;
            column-gap: calcFluid(12, 18);
            margin-bottom: calcFluid(7.33, 11);
        }
        @include _992{
            grid-template-columns: 27px 1fr;
            column-gap: 18px;
            margin-bottom: 10px;
        }
        svg{
            width: 27px;
            height: 50px;
            transition: fill .3s ease;
            @include _1920{
                width: calcFluid(18, 27);
                height: calcFluid(33.33, 50);
            }
            @include _992{
                width: 27px;
                height: 37px;
            }
        }
        span{
            font-size: 21px;
            line-height: 26.25px;
            margin: auto 0;
            transition: color .3s ease;

            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: -webkit-box;

            @include _1920{
                font-size: calcFluid(14, 21);
                line-height: calcFluid(17.50, 26.25);
            }
            @include _992{
                font-size: 16px;
                line-height: 20px;
                -webkit-line-clamp: 3;
            }
        }
    }
    &__size{
        span{
            font-size: 18px;
            line-height: 22.5px;
            color: #636363;
            @include _1920{
                font-size: calcFluid(12, 18);
                line-height: calcFluid(15, 22.5);
            }
            @include _992{
                font-size: 12px;
                line-height: 15px;
            }
            &:first-child{
                margin-right: 5px;
                @include _1920{
                    margin-right: calcFluid(3.33, 5);
                }
                @include _992{
                    margin-right: 10px;
                }
            }
        }
    }
}